/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

// Styles
import "./orderingItemEdit.scss";

// Utils
import {
	Button,
	Col,
	DatePicker,
	Drawer,
	Form,
	message,
	Row,
	Select,
	Typography,
} from "antd";
import { MOMENT_FORMAT, PRODUCT_TYPE } from "@utils/constants";
import { classList } from "@utils/common";
import moment from "moment";

const API = gql`
	query EnumList {
		enumOrderingStatusList {
			id
			name
		}
	}
`;

const ORDERING_ITEM_UPDATE = gql`
	mutation OrderingItemUpdate($input: OrderingItemUpdateInput) {
		orderingItemUpdate(input: $input) {
			id
		}
	}
`;

const ORDERING_ITEM_BULK_UPDATE = gql`
	mutation orderingItemBulkUpdate($input: [OrderingItemUpdateInput]) {
		orderingItemBulkUpdate(input: $input) {
			id
			status {
				id
				name
			}
			serviceEstimatedStartDate
			serviceStartDate
		}
	}
`;

const ORDERING_ITEM_BULK_DELETE = gql`
	mutation orderingItemBulkDelete($orderingItemId: [ID]!) {
		orderingItemBulkDelete(orderingItemId: $orderingItemId)
	}
`;

const OrderingItemEdit = ({
	className,
	orderingItem,
	visible,
	onClose,
	onSuccess,
}) => {
	const productType = orderingItem.product?.__typename ?? "";
	const paymentStatus = orderingItem.paymentStatus.id;

	const {
		clearError,
		errors,
		handleSubmit,
		register,
		setValue,
		watch,
		getValues,
	} = useForm({
		defaultValues: {
			originalStatusId: undefined,
			statusId: undefined,
			serviceEstimatedStartDate: undefined,
			serviceStartDate: undefined,
		},
	});
	console.log(orderingItem);

	const watchValue = watch();

	const [
		originalServiceEstimatedStartDate,
		setOriginalServiceEstimatedStartDate,
	] = useState(undefined);
	const [originalServiceStartDate, setOriginalServiceStartDate] =
		useState(undefined);

	const productTypeIsRental =
		orderingItem.product?.__typename === PRODUCT_TYPE.device ||
		orderingItem.product?.__typename === PRODUCT_TYPE.part;

	const [
		updateOrderingItem,
		{ loading: updateOrderingItemLoading, data: updateOrderingItemData },
	] = useMutation(ORDERING_ITEM_UPDATE);
	const [
		bulkUpdateOrderingItem,
		{
			loading: bulkUpdateOrderingItemLoading,
			data: bulkUpdateOrderingItemData,
		},
	] = useMutation(ORDERING_ITEM_BULK_UPDATE);
	const [
		bulkDeleteOrderingItem,
		{
			loading: bulkDeleteOrderingItemLoading,
			data: bulkDeleteOrderingItemData,
		},
	] = useMutation(ORDERING_ITEM_BULK_DELETE);

	const isLoading =
		updateOrderingItemLoading ||
		bulkUpdateOrderingItemLoading ||
		bulkDeleteOrderingItemLoading;

	const { data: enumList } = useQuery(API);

	useEffect(() => {
		register({ name: "originalStatusId" });
		register({ name: "statusId" });
		register(
			{ name: "serviceEstimatedStartDate" },
			{
				required: !productTypeIsRental,
			}
		);
		register(
			{ name: "serviceStartDate" },
			{
				required:
					productTypeIsRental &&
					(watchValue?.statusId === "reserved" ||
						watchValue?.statusId === "inService"),
			}
		);
	}, [orderingItem.product, productTypeIsRental, register, watchValue]);

	useEffect(() => {
		if (orderingItem) {
			setValue("originalStatusId", orderingItem.status.id);
			setValue("statusId", orderingItem.status.id);
			setValue(
				"serviceEstimatedStartDate",
				orderingItem.serviceEstimatedStartDate
			);
			setValue("serviceStartDate", orderingItem.serviceStartDate);
			orderingItem?.serviceEstimatedStartDate &&
				setOriginalServiceEstimatedStartDate(
					orderingItem.serviceEstimatedStartDate
				);
			orderingItem?.serviceStartDate &&
				setOriginalServiceStartDate(orderingItem.serviceStartDate);
		}
	}, [orderingItem, setValue]);

	const onStatusChange = (value) => {
		setValue("statusId", value);
		setValue("serviceEstimatedStartDate", originalServiceEstimatedStartDate);
		setValue("serviceStartDate", originalServiceStartDate);
	};

	const onServiceEstimatedStartDateChange = (date, dateString) => {
		setValue("serviceEstimatedStartDate", dateString);
	};

	const onServiceStartDateChange = (date, dateString) => {
		setValue("serviceStartDate", dateString);
	};

	const onConfirmClick = useCallback(async () => {
		let serviceEstimatedStartDate = !productTypeIsRental
			? watchValue?.serviceEstimatedStartDate
			: undefined;
		let serviceStartDate =
			(productTypeIsRental && watchValue?.statusId === "inService") ||
			!productTypeIsRental
				? watchValue?.serviceStartDate
				: undefined;
		let statusId = productTypeIsRental ? watchValue?.statusId : undefined;

		try {
			if (
				orderingItem.partOrderingItem &&
				(watchValue?.originalStatusId === "inService" ||
					watchValue?.originalStatusId === "toBeTerminate") &&
				(watchValue?.statusId === "inService" ||
					watchValue?.statusId === "toBeTerminate")
			) {
				const bulkUpdateInput = [
					{
						id: orderingItem.id,
						status: statusId,
						serviceEstimatedStartDate: serviceEstimatedStartDate,
						serviceStartDate: serviceStartDate,
					},
				];
				const bulkDeleteInput = [];
				for (const part of orderingItem.partOrderingItem) {
					if (part.status.id !== "terminated") {
						if (part.status.id === "reserved") {
							if (
								part.delivery === null &&
								watchValue?.statusId !== "toBeTerminate"
							) {
								bulkDeleteInput.push(part.id);
							} else {
								bulkUpdateInput.push({
									id: part.id,
									status: statusId,
									serviceEstimatedStartDate: serviceEstimatedStartDate,
									serviceStartDate: serviceStartDate,
								});
							}
						} else {
							bulkUpdateInput.push({
								id: part.id,
								status: statusId,
								serviceEstimatedStartDate: serviceEstimatedStartDate,
								serviceStartDate: serviceStartDate,
							});
						}
					}
				}

				await bulkUpdateOrderingItem({
					variables: {
						input: bulkUpdateInput,
					},
				});

				if (bulkDeleteInput.length !== 0)
					await bulkDeleteOrderingItem({
						variables: {
							orderingItemId: bulkDeleteInput,
						},
					});
			} else {
				const input = {
					id: orderingItem.id,
					status: statusId,
					serviceEstimatedStartDate: serviceEstimatedStartDate,
					serviceStartDate: serviceStartDate,
				};
				await updateOrderingItem({
					variables: {
						input: input,
					},
				});
			}
			message.success("成功更新訂單項目");
			onSuccess();
		} catch (error) {
			message.error(error.message);
		}

		// eslint-disable-next-line
	}, [updateOrderingItem, orderingItem, watchValue]);

	const getStatusOptions = () => {
		let allowedStatus = [watchValue?.originalStatusId];

		switch (watchValue?.originalStatusId) {
			case "reserved": {
				if (
					(["Service", "Device", "Part"]?.includes(productType) &&
						orderingItem?.user?.disableHkcssInvoice) ||
					paymentStatus === "paid"
				)
					allowedStatus.push("inService");
				break;
			}
			case "inService": {
				allowedStatus.push("toBeTerminate");
				if (productType === "Service") allowedStatus.push("terminated");
				break;
			}
			case "toBeTerminate": {
				allowedStatus.push("inService");
				break;
			}
			default: {
				break;
			}
		}

		return enumList?.enumOrderingStatusList?.map((option) => (
			<Select.Option
				key={option.id}
				value={option.id}
				disabled={!allowedStatus.includes(option.id)}
			>
				{option.name}
			</Select.Option>
		));
	};

	const renderServiceStartDateHeader = () => {
		switch (orderingItem.product?.__typename) {
			case PRODUCT_TYPE.device:
			case PRODUCT_TYPE.part: {
				return <span>實際租借開始日</span>;
			}
			case PRODUCT_TYPE.consumable:
			case PRODUCT_TYPE.service: {
				return <span>實際服務日期</span>;
			}
			default:
				return <span>實際服務日期</span>;
		}
	};

	// (AG-378)[https://talkbox.atlassian.net/browse/AG-378]
	const disabledDate = (current) => {
		const today = moment().endOf("day");
		const pastThirtyDays = moment().subtract(30, "days").endOf("day");
		const currentMonthYear = current.format("MM/YYYY");
		const todayMonthYear = today.format("MM/YYYY");

		if (today.date() <= 15) {
			if (current <= pastThirtyDays || current > today) return true;
		} else {
			if (currentMonthYear !== todayMonthYear) return true;
		}
	};

	const getClassList = () =>
		classList({
			"OrderingItemEditDrawer-Component": true,
			[className]: true,
		});

	return (
		<Drawer
			className={getClassList()}
			title="編輯訂單項目"
			width="50vw"
			visible={visible}
			onClose={onClose}
		>
			<div>
				<Form layout="vertical">
					{productTypeIsRental && (
						<Form.Item>
							<Row>
								<Col>
									<div className="header">
										<span>狀態</span>
									</div>
									<div>
										<Select
											style={{ width: "45%" }}
											placeholder="請選擇狀態"
											value={watchValue?.statusId}
											onChange={onStatusChange}
										>
											{getStatusOptions()}
										</Select>
									</div>
								</Col>
							</Row>
						</Form.Item>
					)}
					{!productTypeIsRental && (
						<Form.Item>
							<Row>
								<Col>
									<div className="header">{"預計服務日期"}</div>
									<div>
										<DatePicker
											style={{ width: "45%" }}
											placeholder="請選擇日期"
											value={
												watchValue?.serviceEstimatedStartDate
													? moment(
															watchValue?.serviceEstimatedStartDate,
															MOMENT_FORMAT
														)
													: null
											}
											onChange={onServiceEstimatedStartDateChange}
										/>
									</div>
									{errors.serviceEstimatedStartDate && (
										<Typography.Text type="danger">請選擇日期</Typography.Text>
									)}
								</Col>
							</Row>
						</Form.Item>
					)}
					<Form.Item>
						<Row>
							<Col>
								<div className="header">
									<span>{renderServiceStartDateHeader()}</span>
								</div>
								<div>
									<DatePicker
										style={{ width: "45%" }}
										disabled={
											(productTypeIsRental &&
												watchValue?.originalStatusId === "inService") ||
											watchValue?.originalStatusId === "toBeTerminate"
										}
										placeholder="請選擇日期"
										value={
											watchValue?.serviceStartDate
												? moment(watchValue?.serviceStartDate, MOMENT_FORMAT)
												: null
										}
										disabledDate={disabledDate}
										onChange={onServiceStartDateChange}
									/>
								</div>
								{errors.serviceStartDate && (
									<Typography.Text type="danger">請選擇日期</Typography.Text>
								)}
							</Col>
						</Row>
					</Form.Item>
				</Form>
			</div>
			<div className="btn-container" style={{ marginTop: "20px" }}>
				<Button
					loading={isLoading}
					className="btn"
					type="primary"
					onClick={handleSubmit(onConfirmClick)}
				>
					儲存
				</Button>
			</div>
		</Drawer>
	);
};

OrderingItemEdit.propTypes = {
	className: PropTypes.string,
	orderingItem: PropTypes.object.isRequired,
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSuccess: PropTypes.func,
};

OrderingItemEdit.defaultProps = {
	className: "",
	visible: false,
};

export default OrderingItemEdit;
