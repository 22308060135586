/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// Actions
import { setIsLoadingAction } from "@actions/storeAction";

// Components
import { Icon, Button, Menu, Dropdown, Modal, notification, Input } from "antd";
import TabsView from "../tabsView";

// Constants
import { PAGE, PAGE_NAME, ACCESS_LEVELS } from "@utils/constants";

// Layouts
import StickyBreadcrumbPageComponent from "@layouts/stickyBreadcrumbPage/stickyBreadcrumbPage";

// Styles
import "./userDetail.scss";

// Utils
import { replaceUrlByParams } from "@utils/api";
import { DEFAULT_MSG } from "@utils/constants";
import { useAccessLevel } from "@utils/hook";

const REFERRAL_INFO_GET = gql`
	query referralInfoGet($id: String) {
		referralInfoGet(id: $id) {
			approvalStatus
			approvalRemark
			personInCharge {
				tbId
				displayName
			}
			user {
				userId
				nameChi
				disableHkcssInvoice
			}
		}
	}
`;

const USER_REFERRAL_INFO_UPDATE = gql`
	mutation UserReferralInfoUpdate($input: UserReferralInfoUpdateInput) {
		userReferralInfoUpdate(input: $input) {
			id
			user {
				userId
			}
		}
	}
`;

const USER_DELETE = gql`
	mutation userDelete($id: ID) {
		userDelete(id: $id)
	}
`;

function UserDetailPage(props) {
	const history = useHistory();
	const dispatch = useDispatch();
	const tabsRef = useRef();
	const store = useSelector((state) => state.storeReducer);
	const { isMatched: isDenied } = useAccessLevel([ACCESS_LEVELS.REFERRALS]);

	const BREADCRUMB = [
		{
			path: replaceUrlByParams(PAGE.USER_DETAIL, { id: props.match.params.id }),
			name: PAGE_NAME.USER_DETAIL,
		},
	];

	const [mode, setMode] = useState("view");
	const [id, setId] = useState(null);
	const [isApprovalModalShow, setIsApprovalModalShow] = useState(false);
	const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
	const [approvalStatus, setApprovalStatus] = useState(undefined);
	const [activeTab, setActiveTab] = useState(null);
	const [userId, setUserId] = useState(null);

	const [
		referralInfoGet,
		{
			loading: referralInfoGetLoading,
			error: referralInfoGetError,
			data: referralInfoGetData,
			refetch: referralInfoGetRefetch,
			networkStatus: referralInfoGetNetworkStatus,
		},
	] = useLazyQuery(REFERRAL_INFO_GET, {
		variables: { id: id },
	});
	const [
		userReferralInfoUpdate,
		{
			loading: userReferralInfoUpdateLoading,
			error: userReferralInfoUpdateError,
			data: userReferralInfoUpdateData,
		},
	] = useMutation(USER_REFERRAL_INFO_UPDATE);

	const [userDelete, { loading: userDeleteLoading }] = useMutation(USER_DELETE);

	const textareaRef = useRef(null);

	useEffect(() => {
		setId(props.match.params.id);

		// eslint-disable-next-line react-hooks/exhaustive-deps,
	}, []);

	useEffect(() => {
		if (id) {
			referralInfoGet();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps,
	}, [id]);

	useEffect(() => {
		if (
			referralInfoGetData &&
			referralInfoGetData.referralInfoGet.user.userId
		) {
			setUserId(referralInfoGetData.referralInfoGet.user.userId);
		}
	}, [referralInfoGetData]);

	// useEffect(() => {
	//   dispatch(setIsLoadingAction(referralInfoGetLoading));

	//   // eslint-disable-next-line react-hooks/exhaustive-deps,
	// }, [referralInfoGetLoading, referralInfoGetError, referralInfoGetData, referralInfoGetNetworkStatus]);

	useEffect(() => {
		dispatch(setIsLoadingAction(userReferralInfoUpdateLoading));

		if (userReferralInfoUpdateData) {
			referralInfoGetRefetch();
			tabsRef.current.userInfoUpdate();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps,
	}, [
		userReferralInfoUpdateLoading,
		userReferralInfoUpdateError,
		userReferralInfoUpdateData,
	]);

	useEffect(() => {
		const { activeTab: tab } = queryString.parse(props.location.search);
		setMode("view");
		setActiveTab(tab);
	}, [props.location.search]);

	const onApprovalModalSubmit = async () => {
		dispatch(setIsLoadingAction(true));
		const res = await userReferralInfoUpdate({
			variables: {
				input: {
					userId: userId,
					referralInfo: {
						id: id,
						approvalStatus: approvalStatus,
						approvalRemark: textareaRef.current.state.value,
					},
					disableHkcssInvoice:
						referralInfoGetData.referralInfoGet.user.disableHkcssInvoice,
				},
			},
		});

		if (res.data.userReferralInfoUpdate) {
			setApprovalStatus(undefined);
			setIsApprovalModalShow(false);
			notification.success({
				message: "成功更新審批狀況",
			});
			referralInfoGet();
		} else {
			notification.error({
				message: DEFAULT_MSG.error,
			});
		}

		dispatch(setIsLoadingAction(false));
	};

	const onApprovalModalCancel = () => {
		setApprovalStatus(undefined);
		setIsApprovalModalShow(false);
	};

	const onBePICClick = async () => {
		const res = await userReferralInfoUpdate({
			variables: {
				input: {
					userId: userId,
					referralInfo: {
						id: id,
						personInCharge: store.user.tbId,
					},
				},
			},
		});

		if (res.data.userReferralInfoUpdate) {
			notification.success({
				message: "您已成為審批負責人",
			});
			referralInfoGet();
		} else {
			notification.error({
				message: DEFAULT_MSG.error,
			});
		}
	};

	const onModeBtnClick = (mode) => {
		switch (mode) {
			case "edit":
				setMode(mode);
				break;
			case "save":
				Modal.confirm({
					title: "確定儲存？",
					okText: "確定",
					okType: "danger",
					cancelText: "取消",
					onOk() {
						switch (activeTab) {
							case "assessment":
								tabsRef.current.assessmentsSave();
								break;
							case "prescription":
								tabsRef.current.prescriptionsSave();
								break;
							default:
						}
						setMode("view");
					},
				});
				break;
			case "cancel":
				Modal.confirm({
					title: "確定取消編輯？",
					content: "未儲存的更改將遺失",
					okText: "確定",
					okType: "danger",
					cancelText: "取消",
					onOk() {
						switch (activeTab) {
							case "assessment":
								tabsRef.current.assessmentsRestore();
								break;
							case "prescription":
								tabsRef.current.prescriptionsRestore();
								break;
							default:
						}
						setMode("view");
					},
				});
				break;
			default:
		}
	};

	const onStatusMenuClick = (e) => {
		setApprovalStatus(e.key);
		setIsApprovalModalShow(true);
	};

	const menu = (
		<Menu className="status-menu" onClick={onStatusMenuClick}>
			<Menu.Item key="pending">未處理</Menu.Item>
			<Menu.Item key="processing">審批中</Menu.Item>
			<Menu.Item key="rejected">拒絕</Menu.Item>
			<Menu.Item key="approved">接納</Menu.Item>
		</Menu>
	);

	const onDeleteUser = async () => {
		try {
			const { data } = await userDelete({ variables: { id: userId } });
			if (data?.userDelete) {
				notification.success({ message: "成功刪除用戶", duration: 5 });
				history.push(PAGE.USER_REGISTERED);
			} else {
				notification.error({ message: "刪除用戶失敗", duration: 5 });
			}
		} catch (err) {
			// console.log(err)
		}
	};

	return (
		<StickyBreadcrumbPageComponent
			className="User-detail-page"
			breadcrumb={BREADCRUMB}
		>
			<div className="status-container">
				<div>
					{!isDenied &&
					activeTab === "info" &&
					referralInfoGetData?.referralInfoGet.approvalStatus !== "rejected" &&
					referralInfoGetData?.referralInfoGet.approvalStatus !== "pending" &&
					referralInfoGetData?.referralInfoGet.approvalStatus !==
						"processing" ? (
						<Link to={replaceUrlByParams(PAGE.ORDERING_DETAIL, { id: userId })}>
							<Button className="btn btn-secondary">訂單紀錄</Button>
						</Link>
					) : (
						false
					)}
				</div>
				{referralInfoGetData &&
					{
						info: (
							<div>
								{!(
									isDenied &&
									["processing", "rejected", "approved"].includes(
										referralInfoGetData?.referralInfoGet.approvalStatus
									)
								) && (
									<Button
										style={{ marginLeft: "8px" }}
										className="btn btn-secondary user-detail-edit-toggle"
										onClick={() => {
											if (mode === "view") {
												setMode("edit");
											} else {
												tabsRef.current.infoSave();
											}
										}}
									>
										{mode === "view" ? "編輯用戶檔案" : "儲存"}
									</Button>
								)}

								<span>審批狀況</span>
								<Dropdown
									className="status-dropdown"
									overlay={menu}
									disabled={isDenied}
								>
									<Button>
										{
											{
												pending: "未處理",
												processing: "審批中",
												rejected: "拒絕",
												approved: "接納",
											}[referralInfoGetData.referralInfoGet.approvalStatus]
										}
										<Icon type="down" />
									</Button>
								</Dropdown>

								{!isDenied && (
									<>
										<Modal
											title="審批備註 / 拒絕此申請者的原因"
											visible={isApprovalModalShow}
											onOk={onApprovalModalSubmit}
											onCancel={onApprovalModalCancel}
										>
											<Input.TextArea
												defaultValue={
													referralInfoGetData?.referralInfoGet.approvalRemark
												}
												placeholder="填寫備註"
												ref={textareaRef}
												rows={4}
											/>
										</Modal>
										{referralInfoGetData?.referralInfoGet.personInCharge
											?.tbId !== store.user.tbId && (
											<Button
												style={{ marginLeft: "8px" }}
												className="btn"
												type="primary"
												onClick={onBePICClick}
											>
												成為審批負責人
											</Button>
										)}
										<Button
											style={{ marginLeft: "8px" }}
											type="danger"
											onClick={() => setIsDeleteModalShow(true)}
											loading={userDeleteLoading}
										>
											刪除用戶
										</Button>
										<Modal
											title="刪除用戶"
											visible={isDeleteModalShow}
											onOk={() => {
												onDeleteUser();
												setIsDeleteModalShow(false);
											}}
											onCancel={() => {
												setIsDeleteModalShow(false);
											}}
											okText="確定"
											cancelText="取消"
											okButtonProps={{
												type: "danger",
												loading: userDeleteLoading,
											}}
										>
											{`確定刪除用戶 ${referralInfoGetData?.referralInfoGet?.user?.nameChi} ?`}
										</Modal>
									</>
								)}
							</div>
						),
						assessment: !isDenied && (
							<div>
								{mode === "view" ? (
									<Button
										className="btn btn-secondary"
										onClick={() => onModeBtnClick("edit")}
									>
										編輯
									</Button>
								) : (
									<div className="mode-btn-container">
										<Button
											className="btn btn-secondary"
											onClick={() => onModeBtnClick("cancel")}
										>
											取消
										</Button>
										<Button
											className="btn"
											type="primary"
											onClick={() => onModeBtnClick("save")}
										>
											儲存
										</Button>
									</div>
								)}
							</div>
						),
						prescription: !isDenied && (
							<>
								{/*{mode === 'view'*/}
								{/*    ? <Button*/}
								{/*        className='btn btn-secondary'*/}
								{/*        onClick={() => onModeBtnClick('edit')}>*/}
								{/*        編輯*/}
								{/*    </Button>*/}
								{/*    : <div className='mode-btn-container'>*/}
								{/*        <Button*/}
								{/*            className='btn btn-secondary'*/}
								{/*            onClick={() => onModeBtnClick('cancel')}>*/}
								{/*            取消*/}
								{/*        </Button>*/}
								{/*        <Button*/}
								{/*            className='btn' type='primary'*/}
								{/*            onClick={() => onModeBtnClick('save')}>*/}
								{/*            儲存*/}
								{/*        </Button>*/}
								{/*    </div>*/}
								{/*}*/}
							</>
						),
					}[activeTab]}
			</div>
			<div className="content-inner">
				{id && (
					<TabsView
						ref={tabsRef}
						id={id}
						userId={userId}
						changeMode={(mode) => setMode(mode)}
						mode={mode}
						activeTab={activeTab}
					/>
				)}
			</div>
		</StickyBreadcrumbPageComponent>
	);
}

export default UserDetailPage;

UserDetailPage.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string,
		hash: PropTypes.string,
		state: PropTypes.object,
	}),
	match: PropTypes.shape({
		path: PropTypes.string,
		url: PropTypes.string,
		isExact: PropTypes.bool,
		params: PropTypes.object,
	}),
};
