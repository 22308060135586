/* eslint  no-unused-vars: 0 */ // --> OFF

import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";
import converter from "number-to-chinese-words";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// Actions
import { setIsLoadingAction } from "@actions/storeAction";
// Components
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Icon,
	Input,
	Modal,
	notification,
	Row,
	Select,
	Table,
	Tabs,
	Tag,
	Typography,
	InputNumber,
	Upload,
} from "antd";
import CreateAssessmentModalComponent from "@components/createAssessmentModal/createAssessmentModal";
import CreatePrescriptionDrawerComponent from "@components/createPrescriptionDrawer/createPrescriptionDrawer";
// Constants
import {
	MOMENT_FORMAT,
	NOTIFICATION_CONFIG,
	PAGE,
	ACCESS_LEVELS,
} from "@utils/constants";
// Styles
import "./tabsView.scss";
// Utils
import { getTokenHeader, replaceUrlByParams } from "@utils/api";
import { hideHkid, _uuid } from "@utils/common";
import { getFileUploadUrl } from "@utils/env";
import { isEmptyObj } from "@utils/validate";
import { ImgUploader } from "@utils/commonComponents";
import { useAccessLevel } from "@utils/hook";
import QRCode from "qrcode.react";

const API = gql`
	{
		enumAssessmentMethodList {
			id
			name
		}
		enumResidenceCategoryList {
			id
			name
		}
		enumReferralUserTypeList {
			id
			name
		}
		enumGenderList {
			id
			name
		}
		enumMaritalStatusList {
			id
			name
		}
		enumEducationLevelList {
			id
			name
		}
		enumLivingConditionList {
			id
			name
		}
		enumRegionList {
			id
			name
			area {
				id
				name
			}
		}
		enumAreaList {
			id
			name
			zone {
				id
				name
			}
		}
		enumZoneList {
			id
			name
		}
		enumLanguageList {
			id
			name
		}
		enumReferralAgencyNameList
		enumDischargeStatusList {
			id
			name
		}
		enumCommunicationSkillList {
			id
			name
		}
		enumVisualAssessmentList {
			id
			name
		}
		enumAuditoryAssessmentList {
			id
			name
		}
		enumCognitiveAbilityAssessmentList {
			id
			name
		}
		enumMedicalRecordList {
			name
			id
		}
		enumIDALList {
			name
			id
		}
		enumFamilyMonthlyIncomeList {
			name
			id
		}
		enumContactRelationList {
			id
			name
		}
		enumLivingStatusList {
			id
			name
		}
		enumContactTypeList {
			name
			id
		}
		enumLivingRelationDisplayList {
			id
			name
		}
	}
`;

const USER_REFERRAL_INFO_CREATE = gql`
	mutation UserReferralInfoCreate($input: UserReferralInfoCreateInput) {
		userReferralInfoCreate(input: $input) {
			id
		}
	}
`;

const USER_REFERRAL_INFO_UPDATE = gql`
	mutation UserReferralInfoUpdate($input: UserReferralInfoUpdateInput) {
		userReferralInfoUpdate(input: $input) {
			id
		}
	}
`;

const USER_GET = gql`
	query userGet($id: ID) {
		userGet(id: $id) {
			disableHkcssInvoice
			allowDisableHkcssInvoice
			attachment {
				key
				url
				name
				size
			}
			userId
			##診斷 tab
			prescription {
				id
				name
				datetime
				personInCharge {
					displayName
				}
				prescriptionDevice {
					device {
						id
						referenceId
						productInfo {
							nameChi
						}
					}
					reason
				}
				relatedAssessment {
					id
				}
			}

			##評估 tab
			assessmentResult {
				id
				title
				referenceId
				assessmentResultId
				assessmentExpectedDate
				assessmentStartDate
				assessmentMethod {
					id
					name
				}
				assessmentStatus {
					id
					name
				}
				personInCharge {
					displayName
				}
				remark
			}

			referralInfo {
				id
				#過往審批紀錄
				user {
					nameChi
					hkid
					telephone
					deleted
				}
				approvedDate

				#審批負責人 ->referralInfo[0]
				personInCharge {
					tbId
					displayName
				}
				#備註
				approvalRemark
				#用戶類型
				referralUserType {
					id
					name
				}

				#申請個案號碼
				referenceId
				#申請人/親屬同意接受本計劃的服務*
				acceptPlanOfService
				#與申請人關係
				relationshipToApplicant
				#家屬姓名
				nameOfRelatives
				#同意日期*
				agreedDate

				##最新轉介機構資料
				#機構名稱*
				referralAgencyName
				#轉介單位
				referralAgencyDepartment
				#傳真號碼
				referralAgencyFax
				#聯絡電話*
				referralAgencyTelephone
				##最新轉介者資料
				#姓名*
				referrerName
				#職位
				referrerPosition
				#聯絡電話*
				referrerTelephone
				#電郵地址*
				referrerEmail
				#轉介日期*
				referralDate
				#出院 / 預算出院
				dischargeStatus {
					id
					name
				}
				#出院 / 預算出院日期
				dischargeDate
				#轉介編號
				referenceId
				#轉介原因
				reason
				#其他轉介原因
				reasonOther
			}
			#中文姓名*
			nameChi
			#英文姓名*
			nameEng
			#性別*
			gender {
				id
			}
			#出生日期*
			dateOfBirth
			#年齡
			#extract from dateOfBirth
			#身份証號碼*
			hkid
			#婚姻狀況*
			maritalStatus {
				id
			}

			#教育程度*
			educationLevel {
				id
			}
			#居住狀況*
			livingCondition {
				id
			}
			#同住關係*
			livingRelation {
				id
			}
			#同住人數(包括自己)*
			numOfLivingTogether

			##住址及聯絡方法

			#住所類別*
			residenceCategory {
				id
			}
			#區域*
			region {
				id
			}
			#地區*
			area {
				id
			}
			#地區內分區*
			zone {
				id
			}
			#屋苑/屋邨名稱*
			estateName
			#大廈/樓宇名稱*
			buildingName
			#樓層*
			floor
			#單位*
			flat
			#需使用郵寄賬單方式
			mailBilling
			#電梯未能直達樓層數量
			elevatorFailToReachFloor
			#到戶安裝/運送安排注意事項
			installationRemark
			#樓梯級
			stairsLevel
			#聯絡電話 (手提)* ->telephone[0]
			telephone
			#選擇加入WhatsApp號碼 ->whatsappNumber[0]
			whatsappNumber {
				phoneNumber
				optIn
			}
			#聯絡電話 (住宅) ->telephoneHome[0]
			telephoneHome
			paymentStatementContactMethod {
				id
			}
			paymentStatementOtherAddress
			paymentStatementEmail
			receiverName
			receiverRelationship
			#其他運送備註
			deliveryRemark
			#能操方言
			language {
				id
			}
			#能操其他方言
			otherLanguage

			##健康狀況

			#病歷 / 健康狀況 (經醫生診斷之病症)
			healthStatus {
				medicalRecord {
					id
					name
				}
				#其他病歷 / 健康狀況
				otherHealthStatus
				#活動能力
				idal {
					id
				}
				#溝通能力
				communicationSkill {
					id
				}
				#左眼視力
				visualLeft {
					id
				}
				#右眼視力
				visualRight {
					id
				}
				#左耳聽力
				auditoryLeft {
					id
				}
				#需使用助聽器?(左)
				hearingAidLeft
				#右耳聽力
				auditoryRight {
					id
				}
				#需使用助聽器?(右)
				hearingAidRight
				#認知能力
				cognitiveAbility {
					id
				}
				#其他認知能力
				otherCognitiveAbility
			}
			##經濟狀況
			economicStatus {
				#家庭住戶每月收入中位數
				familyMonthlyIncome {
					id
				}
				#綜援編號
				cssaFileNum
			}
			##主要照顧者,第i聯絡人 ->contactPerson[0],contactPerson[i]
			contactPerson {
				#姓名*
				name
				#與申請者關係*
				relationship {
					id
					name
				}
				#聯絡電話*
				telephone
				#選擇加入WhatsApp號碼
				whatsappNumber {
					phoneNumber
					optIn
				}
				#支援狀況*
				livingStatus {
					id
				}
				#聯絡人其他支援狀況
				otherLivingStatus
				#聯絡人類型*
				contactType {
					id
				}
			}
			disagreeCapturePhotoAndVideoForPromotion
			username
		}
	}
`;

// const USER_UPDATE = gql`
//   mutation UserUpdate($input: UserUpdateInput) {
//     userUpdate(input: $input) {
//       userId
//     }
//   }
// `;

const ASSESSMENT_RESULT_UPDATE = gql`
	mutation AssessmentResultUpdate($input: AssessmentResultUpdateInput) {
		assessmentResultUpdate(input: $input) {
			id
		}
	}
`;

const ASSESSMENT_RESULT_DELETE = gql`
	mutation AssessmentResultDelete($id: [ID]) {
		assessmentResultDelete(id: $id)
	}
`;

const PRESCRIPTION_DELETE_ON_USER = gql`
	mutation PrescriptionDeleteOnUser($userId: ID, $prescriptionId: ID) {
		prescriptionDeleteOnUser(userId: $userId, prescriptionId: $prescriptionId)
	}
`;

function TabsView(props, ref) {
	const dispatch = useDispatch();
	let history = useHistory();
	const store = useSelector((state) => state.storeReducer);

	notification.config(NOTIFICATION_CONFIG);

	const ID = props.id;
	const USER_ID = props.userId;
	const HKID = props.hkid;
	const MODE = props.mode;
	const IS_READ_ONLY = MODE === "view";

	const NUMBER_OF_CONTACT_PERSON = 2;
	const formRef = useRef(null);

	const {
		clearError,
		control,
		errors,
		getValues,
		handleSubmit,
		register,
		setValue,
		triggerValidation,
		watch,
	} = useForm({
		reValidateMode: "onSubmit",
	});

	// useEffect(() => console.log(errors), [errors]);

	const { isMatched: isDenied } = useAccessLevel([ACCESS_LEVELS.REFERRALS]);

	let assessmentCols = [
		{
			title: "評估號碼",
			dataIndex: "assessmentResultId",
			// sorter: true,
			render: (text, record) =>
				!isDenied ? (
					<Link
						className="link"
						to={replaceUrlByParams(PAGE.USER_ASSESSMENT, {
							id: ID,
							assessmentId: text,
						})}
						target="_blank"
					>
						{record.referenceId}
					</Link>
				) : (
					record.referenceId
				),
		},
		{
			title: "評估名稱",
			dataIndex: "title",
		},
		{
			title: "預計評估日期",
			dataIndex: "assessmentExpectedDate",
			// sorter: true,
			render: (text) => {
				return <span>{text ? text : "-"}</span>;
			},
		},
		{
			title: "實際評估日期",
			dataIndex: "assessmentStartDate",
			// sorter: true,
			render: (text) => (
				<span>{text ? moment(text).format("YYYY-MM-DD") : "-"}</span>
			),
		},
		{
			title: "評估方法",
			dataIndex: "assessmentMethod",
			render: (text, record) =>
				({
					view: <span>{text?.name || "-"}</span>,
					edit: (
						<Select
							onChange={(value) => onAssessmentMethodChange(record, value)}
							placeholder="請選擇方法"
							value={record.assessmentMethod?.id}
							style={{ minWidth: "120px" }}
						>
							{data?.enumAssessmentMethodList.map((method) => (
								<Select.Option key={method.id}>{method.name}</Select.Option>
							))}
						</Select>
					),
				})[MODE],
		},
		{
			title: "負責人",
			dataIndex: "personInCharge.displayName",
			render: (text) => <span>{text || "-"}</span>,
		},
		{
			title: "狀態",
			dataIndex: "assessmentStatus.id",
			render: (text) =>
				({
					complete: <Tag className="tag success">完成</Tag>,
					incomplete: <Tag className="tag warning">未完成</Tag>,
				})[text],
		},
		{
			title: "備註",
			dataIndex: "remark",
			render: (text, record) =>
				({
					view: <span>{text ? text : "-"}</span>,
					edit: (
						<Input
							value={text}
							placeholder="填寫備註"
							onChange={(evt) => onAssessmentRemarkChange(record, evt)}
							style={{ minWidth: "120px" }}
						/>
					),
				})[MODE],
		},
		{
			dataIndex: "action",
			render: (text, record) =>
				MODE === "edit" && (
					<Button
						type="danger"
						shape="circle"
						icon="minus"
						size="small"
						onClick={() => onAssessmentDeleteClick(record)}
					/>
				),
		},
	];
	let prescriptionCols = [
		{
			title: "診斷號碼",
			dataIndex: "id",
			// sorter: true,
			render: (text) =>
				!isDenied ? (
					<Link
						onClick={() => {
							setPrescriptionId(text);
							setViewPrescriptDrawerShow(true);
						}}
					>
						{text}
					</Link>
				) : (
					text
				),
		},
		{
			title: "診斷名稱",
			dataIndex: "name",
		},
		{
			title: "診斷日期",
			dataIndex: "diagnosisDate",
			// sorter: true,
			render: (text, record) => (
				<span>
					{record.datetime
						? moment(record.datetime).format(MOMENT_FORMAT)
						: "-"}
				</span>
			),
		},
		{
			title: "處理人",
			dataIndex: "pic",
			render: (text, record) => (
				<span>{record.personInCharge?.displayName}</span>
			),
		},
		{
			title: "有關評估",
			dataIndex: "relatedAssessment",
			render: (text, record) => (
				<span>
					{record.relatedAssessment
						.map(
							(a) =>
								`${a.id}${
									a.assessmentStartDate
										? `_${moment(+a.assessmentStartDate).format("YYYYMMDD")}`
										: ""
								}`
						)
						.join(", ")}
				</span>
			),
		},
		{
			dataIndex: "action",
			render: (text, record) =>
				MODE === "edit" && (
					<div className="action-container">
						<Link className="link" to={PAGE.ORDER}>
							{" "}
							{/* TODO Update link*/}
							<Button className="btn btn-secondary">新增訂單</Button>
						</Link>
						<Button
							type="danger"
							shape="circle"
							icon="minus"
							size="small"
							onClick={() => onPrescriptionDeleteClick(record)}
						/>
					</div>
				),
		},
	];
	let recordCols = [
		{
			title: "個案號碼",
			dataIndex: "id",
			render: (text, record) =>
				!record?.user?.deleted ? (
					<Link
						className="link"
						rel="noopener noreferrer"
						target="_blank"
						to={replaceUrlByParams(PAGE.USER_DETAIL, { id: text })}
					>
						{record.referenceId}
					</Link>
				) : (
					`${record.referenceId} ${
						record?.user?.deleted ? "(Deleted User)" : ""
					}`
				),
		},
		{
			title: "中文姓名",
			dataIndex: "user.nameChi",
		},
		{
			title: "身份証號碼",
			dataIndex: "user.hkid",
			render: (text) => <span>{hideHkid(text)}</span>,
		},
		{
			title: "電話",
			dataIndex: "user.telephone",
			render: (text) => <span>{text?.filter(Boolean).join(", ") || "-"}</span>,
		},
		{
			title: "轉介日期",
			dataIndex: "referralDate",
		},
		{
			title: "轉介人",
			dataIndex: "referrerName",
		},
		{
			title: "轉介機構",
			dataIndex: "referralAgencyName",
		},
		{
			title: "審批負責人",
			dataIndex: "personInCharge.displayName",
		},
		{
			title: "審批日期",
			dataIndex: "approvedDate",
		},
		{
			dataIndex: "approvalStatus",
			render: (text) =>
				({
					pending: <Tag className="tag">未處理</Tag>,
					processing: <Tag className="tag warning">審批中</Tag>,
					rejected: <Tag className="tag danger">已拒絕</Tag>,
					completed: <Tag className="tag">訂單已完結</Tag>,
				})[text],
		},
	];

	const [activeTab, setActiveTab] = useState(props.activeTab);
	const [assessments, setAssessments] = useState([]);
	const [editModeRemovedAssessmentIds, setEditModeRemovedAssessmentIds] =
		useState([]);
	const [prescriptions, setPrescriptions] = useState([]);
	const [isCreateAssessmentModalShow, setIsCreateAssessmentModalShow] =
		useState(false);
	const [isCreatePrescriptDrawerShow, setIsCreatePrescriptDrawerShow] =
		useState(false);
	const [viewPrescriptDrawerShow, setViewPrescriptDrawerShow] = useState(false);
	const [prescriptionId, setPrescriptionId] = useState("");
	const [isUploading, setIsUploading] = useState(false);
	const [isShowHkid, setIsShowHkid] = useState(MODE === "create" || false);
	const [isCreatePresciption, setIsCreatePresciption] = useState(false);
	const [query, setQuery] = useState({
		sortField: undefined,
		sortOrder: undefined,
	});
	const [contactPersonCount, setContactPersonCount] = useState(3);

	const { loading, error, data } = useQuery(API);

	const [
		userGet,
		{ loading: userGetLoading, data: userGetData, refetch: userGetRefetch },
	] = useLazyQuery(USER_GET, {
		variables: { id: USER_ID },
	});

	const [
		assessmentResultUpdate,
		{
			loading: assessmentResultUpdateLoading,
			data: assessmentResultUpdateData,
		},
	] = useMutation(ASSESSMENT_RESULT_UPDATE);
	const [
		assessmentResultDelete,
		{
			loading: assessmentResultDeleteLoading,
			data: assessmentResultDeleteData,
		},
	] = useMutation(ASSESSMENT_RESULT_DELETE);
	const [
		prescriptionDeleteOnUser,
		{
			loading: prescriptionDeleteOnUserLoading,
			data: prescriptionDeleteOnUserData,
		},
	] = useMutation(PRESCRIPTION_DELETE_ON_USER);
	const [
		userReferralInfoCreate,
		{
			loading: userReferralInfoCreateLoading,
			data: userReferralInfoCreateData,
		},
	] = useMutation(USER_REFERRAL_INFO_CREATE);
	const [
		userReferralInfoUpdate,
		{
			loading: userReferralInfoUpdateLoading,
			data: userReferralInfoUpdateData,
		},
	] = useMutation(USER_REFERRAL_INFO_UPDATE);

	useEffect(() => {
		register({ name: "referralForm" });
		if (!props.activeTab) {
			onTabChange("info");
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (USER_ID) {
			userGet();
		}
		// eslint-disable-next-line
	}, [USER_ID]);

	//prefill
	useEffect(() => {
		dispatch(setIsLoadingAction(userGetLoading));
		if (!userGetLoading && userGetData) {
			setPrescriptions(userGetData.userGet.prescription);
			setAssessments(
				JSON.parse(JSON.stringify(userGetData.userGet.assessmentResult))
			);

			setValue(
				"personInCharge",
				userGetData.userGet?.referralInfo?.[0]?.personInCharge?.displayName
			);
			setValue("remark", userGetData.userGet?.referralInfo?.[0].approvalRemark);
			setValue(
				"referralUserType",
				userGetData.userGet?.referralInfo?.[0]?.referralUserType?.id
			);
			setValue(
				"attachment",
				userGetData.userGet?.attachment.map(({ key, url, name, size }) => ({
					uid: _uuid(),
					key,
					url,
					name,
					size,
				}))
			);
			setValue("disableHkcssInvoice", userGetData.userGet.disableHkcssInvoice); // TODO update value from api

			//refernceId is for display only
			setValue("chineseName", userGetData.userGet.nameChi);
			setValue("englishName", userGetData.userGet.nameEng);
			setValue("gender", userGetData.userGet.gender?.id);
			setValue("dob", moment(userGetData.userGet.dateOfBirth));
			setValue("age", moment().diff(userGetData.userGet.dateOfBirth, "years"));
			setValue("hkid", userGetData.userGet.hkid);
			setValue("maritalStatus", userGetData.userGet.maritalStatus?.id);
			setValue("eduLevel", userGetData.userGet.educationLevel?.id);
			setValue("livingConditions", userGetData.userGet.livingCondition?.id);
			setValue(
				"livingRelation",
				userGetData.userGet.livingRelation
					? userGetData.userGet.livingRelation.map((lr) => lr.id)
					: []
			);
			setValue("numberOfLive", userGetData.userGet.numOfLivingTogether);
			setValue(
				"acceptService",
				userGetData.userGet?.referralInfo[0]?.acceptPlanOfService || false
			);
			setValue(
				"relationshipToApplicant",
				userGetData.userGet?.referralInfo[0]?.relationshipToApplicant
			);
			setValue(
				"nameOfRelatives",
				userGetData.userGet?.referralInfo[0]?.nameOfRelatives
			);
			setValue(
				"agreedDate",
				moment(userGetData.userGet?.referralInfo[0]?.agreedDate)
			);
			setValue(
				"disagreeCapturePhotoAndVideoForPromotion",
				userGetData.userGet.disagreeCapturePhotoAndVideoForPromotion
			);
			// setValue(
			//   "disagreePersonalDataForPromotion",
			//   userGetData.userGet.disagreePersonalDataForPromotion
			// );

			//住址及聯絡方法
			setValue("residenceCategory", userGetData.userGet.residenceCategory?.id);
			setValue("region", userGetData.userGet.region?.id);
			setValue("area", userGetData.userGet.area?.id);
			setValue("zone", userGetData.userGet.zone?.id);
			setValue("estateName", userGetData.userGet.estateName);
			setValue("buildingName", userGetData.userGet.buildingName);
			setValue("floor", userGetData.userGet.floor);
			setValue("flat", userGetData.userGet.flat);
			setValue("billByMail", userGetData.userGet.mailBilling);
			setValue(
				"elevatorFailToReachFloor",
				userGetData.userGet.elevatorFailToReachFloor
			);
			setValue(
				"installationRemark",
				userGetData.userGet.installationRemark ?? []
			);
			setValue("stairsLevel", userGetData.userGet.stairsLevel);
			setValue(
				"telephoneMobile",
				userGetData.userGet.telephone[0] ? userGetData.userGet.telephone[0] : ""
			);
			setValue(
				"telephoneMobileWhatsappOptIn",
				// userGetData.userGet.whatsappNumber.optIn
				!!userGetData.userGet.whatsappNumber[0]?.phoneNumber
			);
			setValue(
				"telephoneHome",
				userGetData.userGet.telephoneHome
					? userGetData.userGet.telephoneHome
					: ""
			);
			setValue(
				"paymentStatementContactMethod",
				userGetData.userGet?.paymentStatementContactMethod[0]?.id
			);
			setValue(
				"paymentStatementOtherAddress",
				userGetData.userGet.paymentStatementOtherAddress
			);
			setValue(
				"paymentStatementEmail",
				userGetData.userGet.paymentStatementEmail
			);
			setValue("receiverName", userGetData.userGet.receiverName);
			setValue(
				"receiverRelationship",
				userGetData.userGet.receiverRelationship
			);
			setValue("deliveryRemark", userGetData.userGet.deliveryRemark);
			setValue(
				"language",
				userGetData.userGet.language
					? userGetData.userGet.language.map((l) => l.id)
					: []
			);
			setValue("otherLanguage", userGetData.userGet.otherLanguage);

			//最新轉介機構資料
			setValue(
				"referralAgencyName",
				userGetData.userGet.referralInfo?.[0]?.referralAgencyName
			);
			setValue(
				"referralAgencyDepartment",
				userGetData.userGet.referralInfo?.[0]?.referralAgencyDepartment
			);
			setValue(
				"referralAgencyFax",
				userGetData.userGet.referralInfo?.[0]?.referralAgencyFax
			);
			setValue(
				"referralAgencyTelephone",
				userGetData.userGet.referralInfo?.[0]?.referralAgencyTelephone
			);

			//最新轉介者資料
			setValue(
				"referrerName",
				userGetData.userGet.referralInfo?.[0]?.referrerName
			);
			setValue(
				"referrerPosition",
				userGetData.userGet.referralInfo?.[0]?.referrerPosition
			);
			setValue(
				"referrerTelephone",
				userGetData.userGet.referralInfo?.[0]?.referrerTelephone
			);
			setValue(
				"referrerEmail",
				userGetData.userGet.referralInfo?.[0]?.referrerEmail
			);
			setValue(
				"referralDate",
				userGetData.userGet.referralInfo?.[0]?.referralDate
					? moment(userGetData.userGet.referralInfo?.[0]?.referralDate)
					: null
			);
			setValue(
				"dischargeStatus",
				userGetData.userGet.referralInfo?.[0]?.dischargeStatus?.id
			);
			setValue(
				"dischargeDate",
				userGetData.userGet.referralInfo?.[0]?.dischargeDate
					? moment(userGetData.userGet.referralInfo?.[0]?.dischargeDate)
					: null
			);
			setValue(
				"referenceId",
				userGetData.userGet.referralInfo?.[0]?.referenceId
			);
			setValue("reason", userGetData.userGet.referralInfo?.[0]?.reason ?? []);
			setValue(
				"reasonOther",
				userGetData.userGet.referralInfo?.[0]?.reasonOther
			);

			//健康狀況
			setValue(
				"medicalRecord",
				userGetData.userGet.healthStatus.medicalRecord
					? userGetData.userGet.healthStatus.medicalRecord.reduce(
							(acc, curr) => [...acc, curr.id],
							[]
						)
					: []
			);
			setValue(
				"otherHealthStatus",
				userGetData.userGet.healthStatus.otherHealthStatus
			);
			setValue(
				"idal",
				userGetData.userGet.healthStatus.idal
					? userGetData.userGet.healthStatus.idal.reduce(
							(acc, curr) => [...acc, curr.id],
							[]
						)
					: []
			);
			setValue(
				"communicationSkill",
				userGetData.userGet.healthStatus.communicationSkill?.id
			);
			setValue("visualLeft", userGetData.userGet.healthStatus.visualLeft?.id);
			setValue("visualRight", userGetData.userGet.healthStatus.visualRight?.id);
			setValue(
				"auditoryLeft",
				userGetData.userGet.healthStatus.auditoryLeft?.id
			);
			setValue(
				"hearingAidLeft",
				userGetData.userGet.healthStatus.hearingAidLeft
			);
			setValue(
				"auditoryRight",
				userGetData.userGet.healthStatus.auditoryRight?.id
			);
			setValue(
				"hearingAidRight",
				userGetData.userGet.healthStatus.hearingAidRight
			);
			setValue(
				"cognitiveAbility",
				userGetData.userGet.healthStatus.cognitiveAbility?.id
			);
			setValue(
				"otherCognitiveAbility",
				userGetData.userGet.healthStatus.otherCognitiveAbility
			);

			//經濟狀況
			setValue(
				"familyMonthlyIncome",
				userGetData.userGet.economicStatus.familyMonthlyIncome?.id
			);
			setValue("cssaFileNum", userGetData.userGet.economicStatus.cssaFileNum);
			//主要照顧者,第i聯絡人
			for (let i = 0; i <= userGetData.userGet.contactPerson.length - 1; i++) {
				setValue(
					`contactPerson${i + 1}Name`,
					userGetData.userGet.contactPerson[i].name
				);
				setValue(
					`contactPerson${i + 1}Relationship`,
					userGetData.userGet.contactPerson[i].relationship
						? userGetData.userGet.contactPerson[i].relationship.map((r) => r.id)
						: []
				);
				setValue(
					`contactPerson${i + 1}Telephone`,
					userGetData.userGet.contactPerson[i]
						? userGetData.userGet.contactPerson[i].telephone
						: ""
				);
				setValue(
					`contactPerson${i + 1}WhatsappOptIn`,
					!!userGetData.userGet.contactPerson[i].whatsappNumber?.phoneNumber
				);
				setValue(
					`contactPerson${i + 1}LivingStatus`,
					userGetData.userGet.contactPerson[i].livingStatus
						? userGetData.userGet.contactPerson[i].livingStatus.reduce(
								(acc, curr) => [...acc, curr.id],
								[]
							)
						: []
				);
				setValue(
					`contactPerson${i + 1}OtherLivingStatus`,
					userGetData.userGet.contactPerson[i].otherLivingStatus
				);
				setValue(
					`contactPerson${i + 1}ContactType`,
					userGetData.userGet.contactPerson[i].contactType
						? userGetData.userGet.contactPerson[i].contactType.map(
								(ct) => ct.id
							)
						: []
				);
			}
		}

		// eslint-disable-next-line
	}, [userGetLoading, userGetData]);

	useEffect(() => {
		setQuery({
			sortField: undefined,
			sortOrder: undefined,
		});
	}, [activeTab]);

	const onIntFieldInput = (number, field) => {
		const roundedNumber = Math.ceil(number);
		setValue(`${field}`, roundedNumber);
	};

	useEffect(() => {
		if (assessmentResultDeleteData) {
			notification.success({
				message: "成功移除評估紀錄",
			});
			userGetRefetch();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assessmentResultDeleteLoading, assessmentResultDeleteData]);

	useEffect(() => {
		if (assessmentResultUpdateData) {
			notification.success({
				message: "成功更新評估紀錄",
			});
			userGetRefetch();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assessmentResultUpdateLoading, assessmentResultUpdateData]);

	useEffect(() => {
		if (!isEmptyObj(errors)) {
			notification.error({
				message: "資料有遺漏，請重試",
			});
		}
	}, [errors]);

	useEffect(() => {
		dispatch(setIsLoadingAction(prescriptionDeleteOnUserLoading));

		if (prescriptionDeleteOnUserData) {
			notification.success({
				message: "成功移除診斷紀錄",
			});
			userGetRefetch();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prescriptionDeleteOnUserLoading, prescriptionDeleteOnUserData]);

	useEffect(() => {
		history.push({
			search: `?activeTab=${activeTab}&${queryString.stringify(query)}`,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
		assessmentCols = assessmentCols.reduce((acc, col) => {
			acc.push({
				...col,
				sortOrder: col.dataIndex === query.sortField ? query.sortOrder : false,
			});
			return acc;
		}, []);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		dispatch(setIsLoadingAction(userReferralInfoCreateLoading));

		if (userReferralInfoCreateData) {
			notification.success({
				message: "成功新增個案",
			});
			history.push(PAGE.USER_PENDING);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userReferralInfoCreateLoading, userReferralInfoCreateData]);

	useEffect(() => {
		if (userReferralInfoUpdateData) {
			notification.success({
				message: "成功更新個案",
			});
			props.changeMode("view");
			userGetRefetch();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userReferralInfoUpdateLoading, userReferralInfoUpdateData]);

	useImperativeHandle(ref, () => ({
		infoSave: async () => await formRef.current.props.onSubmit(),
		assessmentsSave: async () => {
			if (editModeRemovedAssessmentIds?.length) {
				assessmentResultDelete({
					variables: {
						id: editModeRemovedAssessmentIds,
					},
				});
				setEditModeRemovedAssessmentIds([]);
			}

			assessments.forEach(async (assessment) => {
				assessmentResultUpdate({
					variables: {
						input: {
							assessmentResultId: assessment.assessmentResultId,
							assessmentMethod: assessment.assessmentMethod?.id,
							remark: assessment.remark,
						},
					},
				});
			});
		},
		assessmentsRestore: restoreAssessments,
		prescriptionsSave: async () => {
			dispatch(setIsLoadingAction(true));

			let removedPrescriptionIds = [];
			if (prescriptions.length === 0) {
				removedPrescriptionIds = userGetData?.userGet?.prescription.map(
					(p) => p.id
				);
			} else {
				removedPrescriptionIds = userGetData?.userGet?.prescription.map(
					(prescription) => {
						const psrt = prescriptions?.find((p) => p.id === prescription.id);

						if (!psrt) {
							return prescription.id;
						}

						return undefined;
					}
				);
			}

			removedPrescriptionIds = removedPrescriptionIds.filter((id) => id);
			removedPrescriptionIds.forEach(async (prescription) => {
				await prescriptionDeleteOnUser({
					variables: {
						userId: store.user.tbId,
						prescriptionId: prescription,
					},
				});
			});

			dispatch(setIsLoadingAction(false));
		},
		prescriptionsRestore: restorePrescriptions,
		userInfoUpdate: userGetRefetch,
	}));

	const filterOption = (inputValue, option) => {
		if (option.props.children.includes(inputValue)) {
			return option;
		}
	};

	const onFileChange = ({ fileList }) => {
		let fl = [...fileList];

		fl = fl.slice(-1);

		fl = fl.map((file) => {
			if (file.error) {
				notification.error({
					message: "上載失敗",
				});
				setIsUploading(false);
			} else if (file?.status === "uploading") {
				setIsUploading(true);
			} else if (file.response) {
				file.name = file.response.data.name;
				file.size = file.response.data.size;
				file.key = file.response.data.key;
				file.url = file.response.data.url;
				setIsUploading(false);
			}
			return file;
		});

		fl = fl.filter((file) => !file.error);

		setValue("referralForm", fl);
	};

	const onFileRemove = (file) => {
		setValue("referralForm", []);
	};

	const onAssessmentDeleteClick = (record) => {
		Modal.confirm({
			title: "確定移除？",
			okText: "確定",
			okType: "danger",
			cancelText: "取消",
			onOk() {
				setAssessments(
					assessments.filter(
						(a) => a.assessmentResultId !== record.assessmentResultId
					)
				);
				setEditModeRemovedAssessmentIds((state) => [
					...state,
					record.assessmentResultId,
				]);
			},
		});
	};

	const onAssessmentMethodChange = (record, value) => {
		setAssessments(
			assessments.reduce((acc, curr) => {
				if (curr.assessmentResultId === record?.assessmentResultId) {
					if (curr.assessmentMethod) {
						curr.assessmentMethod.id = value;
					} else {
						curr.assessmentMethod = {
							id: value,
						};
					}
				}

				return [...acc, JSON.parse(JSON.stringify(curr))];
			}, [])
		);
	};

	const onAssessmentRemarkChange = (record, evt) => {
		setAssessments(
			assessments.reduce((acc, curr) => {
				if (curr.assessmentResultId === record.assessmentResultId) {
					curr.remark = evt.target.value;
				}
				acc.push(curr);
				return acc;
			}, [])
		);
	};

	const onAssessmentSortChange = (pagination, filters, sorter) => {
		setQuery({
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const onCreateAssessmentClick = () => {
		props.changeMode("view");
		restoreAssessments();
		setIsCreateAssessmentModalShow(true);
	};

	const onCreateAssessmentModalClose = () => {
		setIsCreateAssessmentModalShow(false);
	};

	const onCreateAssessmentModalSubmit = async () => {
		await userGetRefetch();
		onCreateAssessmentModalClose();
	};

	const onCreatePrescriptionClick = () => {
		setIsCreatePresciption(true);
		props.changeMode("view");
		restorePrescriptions();
		setIsCreatePrescriptDrawerShow(true);
	};

	const onCreatePrescriptionDrawerClose = () => {
		setIsCreatePresciption(false);
		setIsCreatePrescriptDrawerShow(false);
	};

	const onCreatePrescriptionDrawerSubmit = async (id) => {
		await userGetRefetch();
		onCreatePrescriptionDrawerClose();
		setPrescriptionId(id);
		setViewPrescriptDrawerShow(true);
	};

	const onUpdatePrescriptionDrawerClose = () => {
		setViewPrescriptDrawerShow(false);
	};

	const onUpdatePrescriptionDrawerSubmit = async (id) => {
		await userGetRefetch();
		setPrescriptionId(id);
		onUpdatePrescriptionDrawerClose();
		setViewPrescriptDrawerShow(true);
	};

	const onPrescriptionDeleteClick = (record) => {
		Modal.confirm({
			title: "確定移除？",
			okText: "確定",
			okType: "danger",
			cancelText: "取消",
			onOk() {
				setPrescriptions(prescriptions.filter((p) => p.id !== record.id));
			},
		});
	};

	const onTabChange = (activeKey) => {
		if (MODE === "edit") {
			notification.warn({
				message: "正在編輯紀錄，請先儲存",
			});
			return;
		}

		history.push({
			search: `?activeTab=${activeKey}`,
		});
		setActiveTab(activeKey);
	};

	const onSubmit = async (data) => {
		console.log({ data });
		let contactPersons = [];
		for (let i = 1; i <= contactPersonCount; i++) {
			const contactPerson = {
				name: data[`contactPerson${i}Name`],
				relationship: data[`contactPerson${i}Relationship`],
				telephone: data[`contactPerson${i}Telephone`]
					? data[`contactPerson${i}Telephone`]
					: "",
				whatsappNumber: data[`contactPerson${i}WhatsappOptIn`]
					? data[`contactPerson${i}Telephone`]
					: undefined,
				livingStatus: data[`contactPerson${i}LivingStatus`],
				otherLivingStatus: data[`contactPerson${i}OtherLivingStatus`],
				contactType: data[`contactPerson${i}ContactType`],
			};
			contactPersons.push(contactPerson);
		}

		let whatsappNumber = [];
		if (data.telephoneMobileWhatsappOptIn) {
			whatsappNumber.push(data.telephoneMobile);
		}
		const paymentStatementContactMethod = data?.paymentStatementContactMethod
			? [data.paymentStatementContactMethod]
			: [];

		const input = {
			referralUserType: data.referralUserType,
			gender: data.gender,
			nameChi: data.chineseName,
			nameEng: data.englishName,
			dateOfBirth: data.dob.format("YYYY-MM-DD"),
			hkid: data.hkid,
			language: data.language,
			otherLanguage: data.otherLanguage,
			telephone: [data.telephoneMobile],
			telephoneHome: data.telephoneHome,
			whatsappNumber: whatsappNumber,
			residenceCategory: data.residenceCategory,
			region: data.region,
			area: data.area,
			zone: data.zone,
			estateName: data.estateName,
			buildingName: data.buildingName,
			floor: data.floor,
			flat: data.flat,
			elevatorFailToReachFloor: data.elevatorFailToReachFloor,
			installationRemark: data.installationRemark?.filter((r) =>
				String(r).trim()
			),
			stairsLevel: data.stairsLevel,
			deliveryRemark: data.deliveryRemark,
			mailBilling: data.billByMail,
			educationLevel: data.eduLevel,
			maritalStatus: data.maritalStatus,
			livingCondition: data.livingConditions,
			numOfLivingTogether: +data.numberOfLive,
			livingRelation: data.livingRelation,
			contactPerson: contactPersons,
			healthStatus: {
				medicalRecord: data.medicalRecord,
				otherHealthStatus: data.otherHealthStatus,
				idal: data.idal,
				communicationSkill: data.communicationSkill,
				visualLeft: data.visualLeft,
				visualRight: data.visualRight,
				auditoryLeft: data.auditoryLeft,
				hearingAidLeft: data.hearingAidLeft,
				auditoryRight: data.auditoryRight,
				hearingAidRight: data.hearingAidRight,
				cognitiveAbility: data.cognitiveAbility,
				otherCognitiveAbility: data.otherCognitiveAbility,
			},
			economicStatus: {
				familyMonthlyIncome: data.familyMonthlyIncome,
				cssaFileNum: data.cssaFileNum,
			},
			referralInfo: {
				id: ID,
				referralAgencyName: data.referralAgencyName,
				referralAgencyDepartment: data.referralAgencyDepartment,
				referralAgencyFax: data.referralAgencyFax,
				referralAgencyTelephone: data.referralAgencyTelephone,
				referrerName: data.referrerName,
				referrerPosition: data.referrerPosition,
				referrerTelephone: data.referrerTelephone,
				referrerEmail: data.referrerEmail,
				referralDate: data.referralDate
					? data.referralDate.format("YYYY-MM-DD")
					: undefined,
				dischargeStatus: data.dischargeStatus,
				dischargeDate: data.dischargeDate
					? data.dischargeDate.format("YYYY-MM-DD")
					: undefined,
				nameOfRelatives: data.nameOfRelatives,
				reason: data.reason?.filter((r) => String(r).trim()),
				reasonOther: data.reasonOther,
				approvalRemark: data.remark,
				referralUserType: data.referralUserType,
				acceptPlanOfService: data.acceptService,
				relationshipToApplicant: data.relationshipToApplicant,
				agreedDate: data.agreedDate.format("YYYY-MM-DD"),
			},
			attachment: data?.attachment?.map(({ key, name, size }) => ({
				key,
				name,
				size,
			})),
			paymentStatementContactMethod,
			paymentStatementOtherAddress:
				data.paymentStatementContactMethod === "otherAddress"
					? data?.paymentStatementOtherAddress
					: undefined,
			paymentStatementEmail:
				data.paymentStatementContactMethod === "email"
					? data?.paymentStatementEmail
					: undefined,
			receiverName: data.receiverName,
			receiverRelationship: data.receiverRelationship,
			disagreeCapturePhotoAndVideoForPromotion:
				data.disagreeCapturePhotoAndVideoForPromotion,
			disableHkcssInvoice: data?.disableHkcssInvoice,
			// disagreePersonalDataForPromotion: data.disagreePersonalDataForPromotion,
		};
		if (MODE === "create") {
			userReferralInfoCreate({
				variables: {
					input: input,
				},
			});
		} else {
			userReferralInfoUpdate({
				variables: {
					input: {
						...input,
						userId: userGetData?.userGet?.userId,
					},
				},
			});
			props.changeMode("view");
		}
	};

	const onVisibleClick = () => setIsShowHkid(!isShowHkid);

	const restoreAssessments = () => {
		setAssessments(
			JSON.parse(JSON.stringify(userGetData?.userGet?.assessmentResult))
		);
		setEditModeRemovedAssessmentIds([]);
	};

	const restorePrescriptions = () => {
		setPrescriptions(
			JSON.parse(JSON.stringify(userGetData?.userGet?.prescription))
		);
	};

	const watchLivingConditions = watch("livingConditions");

	console.log(" getValues().telephoneHome", getValues().telephoneHome);

	useEffect(() => {
		switch (watchLivingConditions) {
			case "livingWithRelative":
				break;
			case "livingWithSpouse":
				setValue("livingRelation", []);
				setValue("numberOfLive", 2);
				break;
			case "livingAlone":
				setValue("livingRelation", []);
				setValue("numberOfLive", 1);
				break;
			default:
				break;
		}
	}, [setValue, watchLivingConditions]);

	const contactPerson = () => {
		const contactPersonForm = [];
		// if (userGetData?.userGet?.contactPerson?.length)
		for (let i = 1; i <= contactPersonCount; i++) {
			contactPersonForm.push(
				<div className="section" key={i}>
					{/* done: group name 緊急聯絡人 changed to 主要照顧者 */}
					<div className="section-title">
						{i > 1 ? `第${converter.toWords(i)}聯絡人` : `主要照顧者`}
					</div>
					<Row gutter={[16, 16]} type="flex">
						<Col xs={6}>
							<Form.Item>
								<span>姓名{i === 1 ? "*" : ""}</span>
								<Controller
									as={<Input />}
									className={errors[`contactPerson${i}Name`] ? "error" : null}
									control={control}
									disabled={IS_READ_ONLY}
									name={`contactPerson${i}Name`}
									rules={{ required: i === 1 ? "請輸入姓名" : false }}
								/>
								<ErrorMessage
									as={<Typography.Text type="danger" />}
									errors={errors}
									name={`contactPerson${i}Name`}
								/>
							</Form.Item>
						</Col>
						<Col xs={6}>
							<Form.Item>
								<span>與申請者關係{i === 1 ? "*" : ""}</span>
								<Controller
									as={
										<Select>
											{data?.enumContactRelationList.map((option) => (
												<Select.Option key={option.id} value={option.id}>
													{option.name}
												</Select.Option>
											))}
										</Select>
									}
									className={
										errors[`contactPerson${i}Relationship`] ? "error" : null
									}
									control={control}
									allowClear={true}
									disabled={IS_READ_ONLY}
									name={`contactPerson${i}Relationship`}
									rules={{ required: i === 1 ? "請選擇與申請者關係" : false }}
								/>
								<ErrorMessage
									as={<Typography.Text type="danger" />}
									errors={errors}
									name={`contactPerson${i}Relationship`}
								/>
							</Form.Item>
						</Col>
						<Col xs={6}>
							<Form.Item>
								<span>聯絡電話 (手提){i === 1 ? "*" : ""}</span>
								<Controller
									as={<Input />}
									className={
										errors[`contactPerson${i}Telephone`] ? "error" : null
									}
									control={control}
									disabled={IS_READ_ONLY}
									name={`contactPerson${i}Telephone`}
									rules={{
										pattern: {
											value: /^([0-9]{8}|null|undefined)$/,
											message: "請輸入八位數字格式",
										},
										required: i === 1 ? "請輸入八位數字格式" : false,
									}}
								/>
								<ErrorMessage
									as={<Typography.Text type="danger" />}
									errors={errors}
									name={`contactPerson${i}Telephone`}
								/>
							</Form.Item>
						</Col>
						<Col className="align-self-end" xs={6}>
							<Form.Item>
								<Controller
									render={({ onChange, onBlur, value, name }) => (
										<Checkbox
											onBlur={onBlur}
											onChange={(e) => onChange(e.target.checked)}
											checked={value}
											name={name}
											disabled={IS_READ_ONLY}
										>
											選擇加入WhatsApp號碼
										</Checkbox>
									)}
									control={control}
									name={`contactPerson${i}WhatsappOptIn`}
									valueName={"checked"}
									defaultValue={false}
								/>
							</Form.Item>
							{userGetData?.userGet?.contactPerson[i - 1]?.whatsappNumber
								?.phoneNumber && (
								<div style={{ display: "flex" }}>
									<div>
										<QRCode value={whatsappQRCodeContent} />
									</div>
									<Form.Item style={{ margin: "0 8px" }}>
										<div>驗證狀態</div>
										{userGetData?.userGet?.contactPerson[i - 1]?.whatsappNumber
											?.optIn ? (
											<Tag color="green">己驗證</Tag>
										) : (
											<Tag color="red">未驗證</Tag>
										)}
									</Form.Item>
								</div>
							)}
						</Col>
						<Col xs={6}>
							<Form.Item>
								<span>支援狀況{i === 1 ? "*" : ""}</span>
								<Controller
									as={
										<Select>
											{data?.enumLivingStatusList.map((option) => (
												<Select.Option key={option.id} value={option.id}>
													{option.name}
												</Select.Option>
											))}
										</Select>
									}
									className={
										errors[`contactPerson${i}LivingStatus`] ? "error" : null
									}
									control={control}
									disabled={IS_READ_ONLY}
									mode="multiple"
									name={`contactPerson${i}LivingStatus`}
									rules={{ required: i === 1 ? "請選擇支援狀況" : false }}
								/>
								<ErrorMessage
									as={<Typography.Text type="danger" />}
									errors={errors}
									name={`contactPerson${i}LivingStatus`}
								/>
							</Form.Item>
						</Col>
						<Col xs={6}>
							<Form.Item>
								<span>聯絡人其他支援狀況</span>
								<Controller
									as={<Input />}
									control={control}
									disabled={IS_READ_ONLY}
									name={`contactPerson${i}OtherLivingStatus`}
								/>
							</Form.Item>
						</Col>
						<Col xs={6}>
							<Form.Item>
								<span>聯絡人類型{i === 1 ? "*" : ""}</span>
								<Controller
									as={
										<Select>
											{data?.enumContactTypeList.map((option) => (
												<Select.Option key={option.id} value={option.id}>
													{option.name}
												</Select.Option>
											))}
										</Select>
									}
									className={
										errors[`contactPerson${i}ContactType`] ? "error" : null
									}
									control={control}
									disabled={IS_READ_ONLY}
									mode="multiple"
									name={`contactPerson${i}ContactType`}
									rules={{ required: i === 1 ? "請選擇聯絡人類型" : false }}
								/>
								<ErrorMessage
									as={<Typography.Text type="danger" />}
									errors={errors}
									name={`contactPerson${i}ContactType`}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
			);
		}

		return <>{contactPersonForm}</>;
	};

	const whatsappQRCodeContent = (() => {
		const whatsappMessage = `我同意通過WhatsApp接收 ${userGetData?.userGet?.username} ${userGetData?.userGet?.nameChi} 的繳費通知書及有關訊息。\nI agree to receive Payment Notice and related messages of ${userGetData?.userGet?.username} ${userGetData?.userGet?.nameEng} over WhatsApp.`;
		return `https://wa.me/85293838371?text=${encodeURIComponent(
			whatsappMessage
		)}`;
	})();

	return (
		<Tabs
			className="Tabs-View"
			defaultActiveKey="info"
			activeKey={activeTab}
			onChange={onTabChange}
		>
			<Tabs.TabPane className="info" tab="申請人資料" key="info">
				<p>* 必填項目</p>
				<Form
					onSubmit={handleSubmit(onSubmit)}
					className="user-info-form"
					ref={formRef}
				>
					{MODE !== "create" && (
						<div className="section">
							<Row gutter={[16, 16]} type="flex">
								<Col xs={6}>
									<Form.Item>
										<span>審批負責人</span>
										<Controller
											as={<Input />}
											disabled={true}
											control={control}
											name={"personInCharge"}
										/>
									</Form.Item>
								</Col>
								<Col className="align-self-end" xs={18}>
									<Form.Item>
										<span>備註</span>
										<Controller
											as={<Input.TextArea />}
											disabled={IS_READ_ONLY}
											control={control}
											name="remark"
											rows={4}
										/>
									</Form.Item>
								</Col>
								{/* wip: be */}
								<Col className="align-self-end" xs={18}>
									<Form.Item>
										<span>用戶類型</span>
										<Controller
											as={
												<Select>
													{(data?.enumReferralUserTypeList ?? [])?.map(
														(option) => (
															<Select.Option
																key={option?.id}
																value={option?.id}
															>
																{option?.name}
															</Select.Option>
														)
													)}
												</Select>
											}
											className={errors.referralUserType ? "error" : null}
											control={control}
											disabled={IS_READ_ONLY}
											mode="single"
											name="referralUserType"
											filterOption={filterOption}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="referralUserType"
										/>
									</Form.Item>
									<Form.Item>
										<Controller
											name="disableHkcssInvoice"
											control={control}
											defaultValue={false}
											render={({ name, onChange, onBlur, value }) => {
												return (
													<Checkbox
														value={value}
														name={name}
														checked={value}
														onChange={(e) => onChange(e.target.checked)}
														disabled={
															MODE === "view" ||
															!userGetData?.userGet?.allowDisableHkcssInvoice
														}
													>
														無需系統出單
													</Checkbox>
												);
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
						</div>
					)}
					<Row className="section" gutter={[16, 16]} type="flex">
						{MODE === "create" && (
							<Col>
								<Form.Item>
									<Controller
										name="disableHkcssInvoice"
										control={control}
										defaultValue={false}
										render={({ name, onChange, onBlur, value }) => {
											return (
												<Checkbox
													value={value}
													name={name}
													checked={value}
													onChange={(e) => onChange(e.target.checked)}
													disabled={MODE === "view"}
												>
													無需系統出單
												</Checkbox>
											);
										}}
									/>
								</Form.Item>
							</Col>
						)}
						{MODE === "create" && (
							<Col className="referral-form" xs={24}>
								<Upload
									name="file"
									className="referral-form-uploader"
									action={getFileUploadUrl()}
									disabled={isUploading}
									fileList={watch("referralForm")}
									headers={{
										"x-token": getTokenHeader(),
										"X-Requested-With": null,
									}}
									onChange={onFileChange}
									onRemove={isUploading ? null : onFileRemove}
									showUploadList={{ showDownloadIcon: false }}
								>
									<Button disabled={isUploading}>
										<Icon type="upload" /> 上載轉介/登記表
									</Button>
								</Upload>
							</Col>
						)}
						<Col xs={24}>
							<Row gutter={[16, 16]} type="flex">
								{MODE !== "create" && (
									<Col xs={6}>
										<Form.Item>
											<span>申請個案號碼</span>
											<Input
												value={
													userGetData?.userGet?.referralInfo?.[0]?.referenceId
												}
												disabled
											/>
										</Form.Item>
									</Col>
								)}
								<Col xs={6}>
									<Form.Item>
										<span>中文姓名*</span>
										<Controller
											as={<Input />}
											className={errors.chineseName ? "error" : null}
											disabled={IS_READ_ONLY}
											control={control}
											rules={{ required: "請輸入中文姓名" }}
											name={"chineseName"}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="chineseName"
										/>
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item>
										<span>英文姓名*</span>
										<Controller
											as={<Input />}
											className={errors.englishName ? "error" : null}
											disabled={IS_READ_ONLY}
											control={control}
											rules={{ required: "請輸入英文姓名" }}
											name={"englishName"}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="englishName"
										/>
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item>
										<span>性別*</span>
										<Controller
											as={
												<Select>
													{data?.enumGenderList.map((option) => (
														<Select.Option key={option.id} value={option.id}>
															{option.name}
														</Select.Option>
													))}
												</Select>
											}
											className={errors.gender ? "error" : null}
											disabled={IS_READ_ONLY}
											control={control}
											name={"gender"}
											rules={{ required: "請選擇性別" }}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="gender"
										/>
									</Form.Item>
								</Col>
								<Col className="dob" xs={6}>
									<Form.Item>
										<span>出生日期*</span>
										<div id="xxx" style={{ position: "relative" }}>
											<Controller
												as={<DatePicker />}
												className={errors.dob ? "error" : null}
												disabled={IS_READ_ONLY}
												format={MOMENT_FORMAT}
												control={control}
												name="dob"
												placeholder={"YYYY-MM-DD"}
												rules={{ required: "請輸入出生日期" }}
												onChange={([date]) => {
													if (date) {
														setValue("age", moment().diff(date, "years"));
													} else {
														setValue("age", undefined);
													}
													return date;
												}}
											/>
										</div>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="dob"
										/>
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item>
										<span>年齡</span>
										<Controller
											as={<Input />}
											control={control}
											disabled
											name="age"
										/>
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item>
										<span>身份証號碼*</span>
										<Controller
											disabled={HKID ? true : false}
											as={
												<Input
													onBlur={(evt) => {
														const hkid = evt.target.value;
														if (hkid) {
															triggerValidation("hkid").then((isValid) => {
																if (isValid) {
																	setIsShowHkid(false);
																} else {
																	setIsShowHkid(true);
																}
															});
														} else {
															clearError("hkid");
														}
													}}
													suffix={
														<Icon
															onClick={() => !errors.hkid && onVisibleClick()}
															style={{
																cursor: `${
																	errors.hkid ? "not-allowed" : "pointer"
																}`,
															}}
															type={isShowHkid ? "eye-invisible" : "eye"}
														/>
													}
												/>
											}
											className={`${errors.hkid ? "error" : ""} ${
												isShowHkid ? "" : "hide"
											}`}
											control={control}
											name="hkid"
											rules={{
												required: "請輸入身份証號碼",
												pattern: {
													value: /^[A-Z]{1,2}[0-9]{6}[0-9,A]$/i,
													message: "如身份証號碼為A123456(7)，請輸入A1234567",
												},
											}}
											defaultValue={HKID}
										/>
										<Input
											className={!isShowHkid ? "" : "hide"}
											disabled
											value={hideHkid(getValues().hkid)}
											onClick={onVisibleClick}
											suffix={
												<Icon
													onClick={onVisibleClick}
													style={{
														color: "unset",
														cursor: "pointer",
													}}
													type={isShowHkid ? "eye-invisible" : "eye"}
												/>
											}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="hkid"
										/>
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item>
										<span>婚姻狀況*</span>
										<Controller
											as={
												<Select>
													{data?.enumMaritalStatusList.map((option) => (
														<Select.Option key={option.id} value={option.id}>
															{option.name}
														</Select.Option>
													))}
												</Select>
											}
											className={errors.maritalStatus ? "error" : null}
											control={control}
											disabled={IS_READ_ONLY}
											name="maritalStatus"
											rules={{ required: "請選擇婚姻狀況" }}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="maritalStatus"
										/>
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item>
										<span>教育程度*</span>
										<Controller
											as={
												<Select>
													{data?.enumEducationLevelList.map((option) => (
														<Select.Option key={option.id} value={option.id}>
															{option.name}
														</Select.Option>
													))}
												</Select>
											}
											className={errors.eduLevel ? "error" : null}
											control={control}
											disabled={IS_READ_ONLY}
											name="eduLevel"
											rules={{ required: "請選擇教育程度" }}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="eduLevel"
										/>
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item>
										<span>居住狀況*</span>
										<Controller
											as={
												<Select>
													{data?.enumLivingConditionList.map((option) => (
														<Select.Option key={option.id} value={option.id}>
															{option.name}
														</Select.Option>
													))}
												</Select>
											}
											className={errors.livingConditions ? "error" : null}
											control={control}
											disabled={IS_READ_ONLY}
											name="livingConditions"
											rules={{ required: "請選擇居住狀況" }}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="livingConditions"
										/>
									</Form.Item>
								</Col>
								<Col xs={6}>
									<Form.Item>
										<span>同住關係*</span>
										<Controller
											as={
												<Select>
													{data?.enumLivingRelationDisplayList.map((option) => (
														<Select.Option key={option.id} value={option.id}>
															{option.name}
														</Select.Option>
													))}
												</Select>
											}
											className={errors.livingRelation ? "error" : null}
											control={control}
											disabled={
												IS_READ_ONLY ||
												watchLivingConditions !== "livingWithRelative"
											}
											mode="multiple"
											name="livingRelation"
											rules={{ required: "請選擇與親友/外傭同住關係" }}
											filterOption={filterOption}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="livingRelation"
										/>
									</Form.Item>
								</Col>
								<Col xs={6} xl={6}>
									<Form.Item>
										<span>同住人數(包括自己)*</span>
										<Controller
											as={<Input />}
											className={errors.numberOfLive ? "error" : null}
											control={control}
											disabled={
												IS_READ_ONLY ||
												watchLivingConditions !== "livingWithRelative"
											}
											min={1}
											name="numberOfLive"
											type="number"
											rules={{ required: "請輸入同住人數(包括自己)" }}
										/>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="numberOfLive"
										/>
									</Form.Item>
								</Col>
								<Col xs={14} xl={8}>
									<Form.Item>
										<Controller
											control={control}
											name="acceptService"
											rules={{ required: "請同意接受本計劃的服務" }}
											defaultValue={false}
											render={({ onChange, onBlur, value, name }) => (
												<Checkbox
													onBlur={onBlur}
													onChange={(e) => onChange(e.target.checked)}
													checked={value}
													name={name}
													disabled={IS_READ_ONLY}
													className={errors.acceptService ? "error" : null}
												>
													申請人/親屬同意接受本計劃的服務*
												</Checkbox>
											)}
										/>
										<div>
											<ErrorMessage
												as={<Typography.Text type="danger" />}
												errors={errors}
												name="acceptService"
											/>
										</div>
										<Controller
											as={<Input />}
											control={control}
											disabled={IS_READ_ONLY}
											name="relationshipToApplicant"
											placeholder="與申請人關係"
										/>
									</Form.Item>
								</Col>
								<Col xs={10} xl={6}>
									<Form.Item>
										<span>家屬姓名</span>
										<Controller
											as={<Input />}
											disabled={IS_READ_ONLY}
											control={control}
											name={"nameOfRelatives"}
										/>
									</Form.Item>
								</Col>
								<Col className="agreed-date" xs={12} xl={6}>
									<Form.Item>
										<span>同意日期*</span>
										<div>
											<Controller
												as={<DatePicker />}
												className={errors.agreedDate ? "error" : null}
												control={control}
												disabled={IS_READ_ONLY}
												format={MOMENT_FORMAT}
												name="agreedDate"
												placeholder={undefined}
												rules={{ required: "請輸入同意日期" }}
											/>
										</div>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="agreedDate"
										/>
									</Form.Item>
								</Col>
								<Col xs={6} xl={4}>
									<Form.Item>
										<span>簽署檔案*</span>
										<div>
											<Controller
												control={control}
												rules={{
													validate: (data) =>
														data?.length > 0 ? true : "請上載簽署檔案",
												}}
												render={({ value, onChange, ref }) => (
													<ImgUploader
														value={value}
														onChange={onChange}
														ref={ref}
														disabled={IS_READ_ONLY}
														btnText="上載簽署檔案"
													/>
												)}
												name="attachment"
											/>
										</div>
										<ErrorMessage
											as={<Typography.Text type="danger" />}
											errors={errors}
											name="attachment"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} xl={24}>
									<Form.Item>
										<Controller
											control={control}
											name="disagreeCapturePhotoAndVideoForPromotion"
											defaultValue={false}
											render={({ onChange, onBlur, value, name }) => (
												<Checkbox
													onBlur={onBlur}
													onChange={(e) => onChange(e.target.checked)}
													checked={value}
													name={name}
													disabled={IS_READ_ONLY}
													className={
														errors.disagreeCapturePhotoAndVideoForPromotion
															? "error"
															: null
													}
												>
													本人及本人的照顧者不同意本服務於提供服務/活動時進行拍照及攝錄，所有服務/活動相片、短片及錄音將有機會用作服務記錄、宣傳推廣及檢討之用。
												</Checkbox>
											)}
										/>
									</Form.Item>
									{/* <Form.Item>
                    <Controller
                      control={control}
                      name="disagreePersonalDataForPromotion"
                      defaultValue={false}
                      render={({ onChange, onBlur, value, name }) => (
                        <Checkbox
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                          name={name}
                          disabled={IS_READ_ONLY}
                          className={
                            errors.disagreePersonalDataForPromotion
                              ? "error"
                              : null
                          }
                        >
                          申請人及照顧者不同意本服務使用申請人提供的個人資料(包括申請人的姓名、電話、電郵及地址等作推廣貴會服務/活動之用途
                        </Checkbox>
                      )}
                    />
                  </Form.Item> */}
								</Col>
							</Row>
						</Col>
					</Row>
					<div className="section">
						<div className="section-title">住址及聯絡方法</div>
						<Row gutter={[16, 16]} type="flex">
							<Col xs={6}>
								<Form.Item>
									<span>住所類別*</span>
									<Controller
										as={
											<Select>
												{data?.enumResidenceCategoryList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										className={errors.residenceCategory ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="residenceCategory"
										rules={{ required: "請選擇住所類別" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="residenceCategory"
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>區域*</span>
									<Controller
										as={
											<Select>
												{data?.enumRegionList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										className={errors.region ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="region"
										rules={{ required: "請選擇區域" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="region"
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>地區*</span>
									<Controller
										as={
											<Select>
												{(
													data?.enumRegionList.find(
														(option) => watch("region") === option.id
													)?.area ||
													data?.enumAreaList ||
													[]
												).map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										className={errors.area ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="area"
										rules={{ required: "請選擇地區" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="area"
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>地區內分區*</span>
									<Controller
										as={
											<Select>
												{(
													data?.enumAreaList.find(
														(option) => watch("area") === option.id
													)?.zone ||
													data?.enumZoneList ||
													[]
												).map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										className={errors.zone ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="zone"
										rules={{ required: "請選擇地區內分區" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="zone"
									/>
								</Form.Item>
							</Col>
							<Col xs={5}>
								<Form.Item>
									<span>屋苑/屋邨名稱*</span>
									<Controller
										as={<Input />}
										className={errors.estateName ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="estateName"
										rules={{ required: "請輸入屋苑/屋邨名稱" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="estateName"
									/>
								</Form.Item>
							</Col>
							<Col xs={5}>
								<Form.Item>
									<span>大廈/樓宇名稱*</span>
									<Controller
										as={<Input />}
										className={errors.buildingName ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="buildingName"
										rules={{ required: "請輸入大廈/樓宇名稱" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="buildingName"
									/>
								</Form.Item>
							</Col>
							<Col xs={4}>
								<Form.Item>
									<span>樓層*</span>
									<Controller
										as={<Input />}
										className={errors.floor ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="floor"
										rules={{ required: "請輸入樓層" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="floor"
									/>
								</Form.Item>
							</Col>
							<Col xs={4}>
								<Form.Item>
									<span>單位*</span>
									<Controller
										as={<Input />}
										className={errors.flat ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="flat"
										rules={{ required: "請輸入單位" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="flat"
									/>
								</Form.Item>
							</Col>
							<Col className="align-self-end" xs={6}>
								<Form.Item>
									<div>
										<Controller
											render={({ onChange, onBlur, value, name }) => (
												<Checkbox
													onBlur={onBlur}
													onChange={(e) => onChange(e.target.checked)}
													checked={value}
													name={name}
													disabled={IS_READ_ONLY}
												>
													需使用郵寄賬單方式
												</Checkbox>
											)}
											control={control}
											name="billByMail"
											valueName="checked"
											defaultValue={false}
										/>
									</div>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>電梯未能直達樓層數量</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										min={0}
										precision={0}
										name="elevatorFailToReachFloor"
										type="number"
										defaultValue={0}
									/>
								</Form.Item>
							</Col>
							<Col xs={12}>
								{/* wip: be */}
								<Form.Item>
									<span>到戶安裝/運送安排注意事項</span>
									<Controller
										as={
											<Select>
												{(userGetData?.userGet?.installationRemark ?? []).map(
													(option) => (
														<Select.Option key={option} value={option}>
															{option}
														</Select.Option>
													)
												)}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										name="installationRemark"
										mode="tags"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>樓梯級</span>
									<br />
									<Controller
										as={<InputNumber />}
										control={control}
										disabled={IS_READ_ONLY}
										precision={0}
										min={0}
										name="stairsLevel"
										defaultValue={0}
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>聯絡電話 (手提)*</span>
									<Controller
										as={<Input />}
										className={errors.telephoneMobile ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="telephoneMobile"
										rules={{
											required: "請輸入聯絡電話",
											minLength: { value: 8, message: "請輸入八位數字格式" },
											maxLength: { value: 8, message: "請輸入八位數字格式" },
											pattern: {
												value: /^([0-9]+|null)$/,
												message: "請輸入八位數字格式",
											},
										}}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="telephoneMobile"
									/>
								</Form.Item>
							</Col>
							<Col xs={8}>
								<Form.Item>
									<Controller
										render={({ onChange, onBlur, value, name }) => (
											<Checkbox
												onBlur={onBlur}
												onChange={(e) => onChange(e.target.checked)}
												checked={value}
												name={name}
												disabled={IS_READ_ONLY}
											>
												選擇加入WhatsApp號碼
											</Checkbox>
										)}
										control={control}
										name={`telephoneMobileWhatsappOptIn`}
										valueName={"checked"}
										defaultValue={false}
									/>
								</Form.Item>
								{userGetData?.userGet?.whatsappNumber[0]?.phoneNumber && (
									<div style={{ display: "flex" }}>
										<div>
											<QRCode value={whatsappQRCodeContent} />
										</div>
										<Form.Item style={{ margin: "0 8px" }}>
											<div>驗證狀態</div>
											{userGetData?.userGet?.whatsappNumber[0]?.optIn ? (
												<Tag color="green">己驗證</Tag>
											) : (
												<Tag color="red">未驗證</Tag>
											)}
										</Form.Item>
									</div>
								)}
							</Col>
							<Col xs={10}>
								<Form.Item>
									<span>聯絡電話 (住宅)</span>
									<Controller
										as={<Input />}
										className={errors.telephoneHome ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="telephoneHome"
										rules={{
											pattern: {
												value: /^(^$|[0-9]{8}|null|undefined)$/,
												message: "請輸入八位數字格式",
											},
											required: false,
										}}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="telephoneHome"
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>聯絡方法 (賬單寄送方法)</span>
									<Controller
										control={control}
										name="paymentStatementContactMethod"
										render={({ name, onBlur, onChange, value }) => (
											<Select
												disabled={IS_READ_ONLY}
												className={
													errors.paymentStatementContactMethod ? "error" : null
												}
												name={name}
												onBlur={onBlur}
												onChange={onChange}
												value={value}
											>
												<Select.Option key="userMobilePhone">
													用戶聯絡電話 (手提)
												</Select.Option>
												<Select.Option key="firstContactPersonPhone">
													主要照顧者聯絡電話
												</Select.Option>
												<Select.Option key="secondContactPersonPhone">
													第二聯絡人聯絡電話
												</Select.Option>
												<Select.Option key="thirdContactPersonPhone">
													第三聯絡人聯絡電話
												</Select.Option>
												<Select.Option key="userAddress">
													用戶主要地址
												</Select.Option>
												<Select.Option key="otherAddress">
													其他地址
												</Select.Option>
												<Select.Option key="email">電郵</Select.Option>
											</Select>
										)}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="paymentStatementContactMethod"
									/>
								</Form.Item>
							</Col>
							<Col xs={8}>
								<Form.Item
									{...(!["otherAddress", "email"].includes(
										watch()?.paymentStatementContactMethod
									) && { style: { display: "none" } })}
								>
									<Row>
										<span>聯絡方法 (備註)</span>
									</Row>
									<Row
										gutter={[8, 8]}
										{...(watch("paymentStatementContactMethod") !==
											"otherAddress" && { style: { display: "none" } })}
									>
										<Col xs={4}>其他地址</Col>
										<Col xs={20}>
											<Controller
												as={<Input />}
												className={
													errors.paymentStatementOtherAddress ? "error" : null
												}
												control={control}
												disabled={IS_READ_ONLY}
												name="paymentStatementOtherAddress"
												rules={{
													required:
														watch("paymentStatementContactMethod") ===
														"otherAddress"
															? "請填寫其他地址"
															: false,
												}}
											/>
											<ErrorMessage
												as={<Typography.Text type="danger" />}
												errors={errors}
												name="paymentStatementOtherAddress"
											/>
										</Col>
									</Row>
									<Row
										gutter={[8, 8]}
										{...(watch("paymentStatementContactMethod") !== "email" && {
											style: { display: "none" },
										})}
									>
										<Col xs={4}>電郵</Col>
										<Col xs={20}>
											<Controller
												as={<Input />}
												className={
													errors.paymentStatementEmail ? "error" : null
												}
												control={control}
												disabled={IS_READ_ONLY}
												name="paymentStatementEmail"
												rules={{
													required:
														watch("paymentStatementContactMethod") === "email"
															? "請填寫電郵"
															: false,
												}}
											/>
											<ErrorMessage
												as={<Typography.Text type="danger" />}
												errors={errors}
												name="paymentStatementEmail"
											/>
										</Col>
									</Row>
								</Form.Item>
							</Col>
							<Col xs={5}>
								<Form.Item>
									<span>收訊人姓名</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										name="receiverName"
									/>
								</Form.Item>
							</Col>
							<Col xs={5}>
								<Form.Item>
									<span>收訊人關係</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										name="receiverRelationship"
									/>
								</Form.Item>
							</Col>
							{/* wip: to be delete */}
							<Col xs={24}>
								<Form.Item>
									<span>其他運送備註</span>
									<Controller
										as={<Input.TextArea />}
										control={control}
										disabled={IS_READ_ONLY}
										name="deliveryRemark"
										rows={4}
									/>
								</Form.Item>
							</Col>

							<Col xs={6}>
								<Form.Item>
									<span>能操方言</span>
									<Controller
										as={
											<Select>
												{data?.enumLanguageList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										mode="multiple"
										name="language"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>能操其他方言</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										name="otherLanguage"
									/>
								</Form.Item>
							</Col>
						</Row>
					</div>
					<div className="section">
						<div className="section-title">最新轉介機構資料</div>
						<Row gutter={[16, 16]} type="flex">
							<Col xs={6}>
								<Form.Item>
									<span>機構名稱*</span>
									<Controller
										as={
											<Select>
												{data?.enumReferralAgencyNameList.map((option) => (
													<Select.Option key={option} value={option}>
														{option}
													</Select.Option>
												))}
											</Select>
										}
										className={errors.referralAgencyName ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="referralAgencyName"
										rules={{ required: "請選擇機構名稱" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="referralAgencyName"
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>轉介單位</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										name="referralAgencyDepartment"
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>傳真號碼</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										name="referralAgencyFax"
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>聯絡電話*</span>
									<Controller
										as={<Input />}
										className={errors.referralAgencyTelephone ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="referralAgencyTelephone"
										rules={{
											required: "請輸入聯絡電話",
											minLength: { value: 8, message: "請輸入八位數字格式" },
											maxLength: { value: 8, message: "請輸入八位數字格式" },
											pattern: {
												value: /^[0-9]+$/,
												message: "請輸入八位數字格式",
											},
										}}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="referralAgencyTelephone"
									/>
								</Form.Item>
							</Col>
						</Row>
					</div>
					<div className="section">
						<div className="section-title">最新轉介者資料</div>
						<Row gutter={[16, 16]} type="flex">
							<Col xs={8} lg={6}>
								<Form.Item>
									<span>轉介者姓名*</span>
									<Controller
										as={<Input />}
										className={errors.referrerName ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="referrerName"
										rules={{ required: "請輸入姓名" }}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="referrerName"
									/>
								</Form.Item>
							</Col>
							<Col xs={8} lg={6}>
								<Form.Item>
									<span>職位</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										name="referrerPosition"
									/>
								</Form.Item>
							</Col>
							<Col xs={8} lg={6}>
								<Form.Item>
									<span>聯絡電話*</span>
									<Controller
										as={<Input />}
										className={errors.referrerTelephone ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="referrerTelephone"
										rules={{
											required: "請輸入聯絡電話",
											minLength: { value: 8, message: "請輸入八位數字格式" },
											maxLength: { value: 8, message: "請輸入八位數字格式" },
											pattern: {
												value: /^([0-9]+|null)$/,
												message: "請輸入八位數字格式",
											},
										}}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="referrerTelephone"
									/>
								</Form.Item>
							</Col>
							<Col xs={8} lg={6}>
								<Form.Item>
									<span>電郵地址*</span>
									<Controller
										as={<Input />}
										className={errors.referrerEmail ? "error" : null}
										control={control}
										disabled={IS_READ_ONLY}
										name="referrerEmail"
										rules={{
											required: "請輸入電郵地址",
										}}
									/>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="referrerEmail"
									/>
								</Form.Item>
							</Col>
							<Col xs={8} lg={6}>
								<Form.Item>
									<span>轉介日期*</span>
									<div>
										<Controller
											as={<DatePicker />}
											className={errors.referralDate ? "error" : null}
											control={control}
											disabled={IS_READ_ONLY}
											format={MOMENT_FORMAT}
											name="referralDate"
											placeholder={undefined}
											rules={{ required: "請輸轉介日期" }}
										/>
									</div>
									<ErrorMessage
										as={<Typography.Text type="danger" />}
										errors={errors}
										name="referralDate"
									/>
								</Form.Item>
							</Col>
							<Col xs={8} lg={6}>
								<Form.Item>
									<span>出院 / 預算出院</span>
									<Controller
										as={
											<Select>
												{data?.enumDischargeStatusList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										name="dischargeStatus"
									/>
								</Form.Item>
							</Col>
							<Col xs={8} lg={6}>
								<Form.Item>
									<span>出院 / 預算出院日期</span>
									<div>
										<Controller
											as={<DatePicker />}
											control={control}
											disabled={IS_READ_ONLY}
											format={MOMENT_FORMAT}
											name="dischargeDate"
											placeholder={undefined}
										/>
									</div>
								</Form.Item>
							</Col>
							{MODE !== "create" && (
								<Col xs={8} lg={6}>
									<Form.Item>
										<span>轉介編號</span>
										<Controller
											as={<Input />}
											control={control}
											disabled
											name="referenceId"
										/>
									</Form.Item>
								</Col>
							)}
							<Col xs={24}>
								<Form.Item>
									<span>轉介原因</span>
									<Controller
										as={
											<Select>
												{data?.reason?.map((item) => (
													<Select.Option key={item} value={item}>
														{item}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										mode="tags"
										name="reason"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col xs={24}>
								<Form.Item>
									<span>其他轉介原因</span>
									<Controller
										as={<Input.TextArea />}
										control={control}
										disabled={IS_READ_ONLY}
										name="reasonOther"
										defaultValue={false}
										rows={4}
									/>
								</Form.Item>
							</Col>
						</Row>
					</div>
					<div className="section">
						<div className="section-title">健康狀況</div>
						<Row gutter={[16, 16]} type="flex">
							<Col xs={12}>
								<Form.Item>
									<span>病歷 / 健康狀況 (經醫生診斷之病症)</span>
									<Controller
										as={
											// be change: add options, should not matter on fe if be field name remand the same
											<Select>
												{data?.enumMedicalRecordList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										mode="multiple"
										name="medicalRecord"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item>
									<span>其他病歷 / 健康狀況</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										name="otherHealthStatus"
									/>
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item>
									<span>活動能力</span>
									<Controller
										as={
											<Select>
												{data?.enumIDALList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										mode="multiple"
										name="idal"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col xs={12} lg={4}>
								<Form.Item>
									<span>溝通能力</span>
									<Controller
										as={
											<Select>
												{data?.enumCommunicationSkillList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										name="communicationSkill"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col xs={6} lg={4}>
								<Form.Item>
									<span>左眼視力</span>
									<Controller
										as={
											<Select>
												{data?.enumVisualAssessmentList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										name="visualLeft"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col xs={6} lg={4}>
								<Form.Item>
									<span>右眼視力</span>
									<Controller
										as={
											<Select>
												{data?.enumVisualAssessmentList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										name="visualRight"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>左耳聽力</span>
									<Controller
										as={
											<Select>
												{data?.enumAuditoryAssessmentList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										name="auditoryLeft"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col className="align-self-end" xs={6}>
								<Form.Item>
									<Controller
										render={({ onChange, onBlur, value, name }) => (
											<Checkbox
												onBlur={onBlur}
												onChange={(e) => onChange(e.target.checked)}
												checked={value}
												name={name}
												disabled={IS_READ_ONLY}
											>
												需使用助聽器?
											</Checkbox>
										)}
										control={control}
										name="hearingAidLeft"
										valueName={"checked"}
										defaultValue={false}
									/>
								</Form.Item>
							</Col>
							<Col xs={6}>
								<Form.Item>
									<span>右耳聽力</span>
									<Controller
										as={
											<Select>
												{data?.enumAuditoryAssessmentList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										name="auditoryRight"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col className="align-self-end" xs={6}>
								<Form.Item>
									<Controller
										render={({ onChange, onBlur, value, name }) => (
											<Checkbox
												onBlur={onBlur}
												onChange={(e) => onChange(e.target.checked)}
												checked={value}
												name={name}
												disabled={IS_READ_ONLY}
											>
												需使用助聽器?
											</Checkbox>
										)}
										control={control}
										name="hearingAidRight"
										valueName={"checked"}
										defaultValue={false}
									/>
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item>
									<span>認知能力</span>
									<Controller
										as={
											<Select>
												{data?.enumCognitiveAbilityAssessmentList.map(
													(option) => (
														<Select.Option key={option.id} value={option.id}>
															{option.name}
														</Select.Option>
													)
												)}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										name="cognitiveAbility"
										filterOption={filterOption}
									/>
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item>
									<span>其他認知能力</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										name="otherCognitiveAbility"
									/>
								</Form.Item>
							</Col>
						</Row>
					</div>
					<div className="section">
						<div className="section-title">經濟狀況</div>
						<Row gutter={[16, 16]} type="flex">
							<Col xs={12}>
								<Form.Item>
									{/* // be change: change options, should not matter on fe if be field name remand the same */}
									<span>
										家庭住戶每月收入中位數
										<br />
										(根據社署長者社區照顧服務券試驗計劃為評估準則)
									</span>
									<Controller
										as={
											<Select>
												{data?.enumFamilyMonthlyIncomeList.map((option) => (
													<Select.Option key={option.id} value={option.id}>
														{option.name}
													</Select.Option>
												))}
											</Select>
										}
										control={control}
										disabled={IS_READ_ONLY}
										filterOption={filterOption}
										name="familyMonthlyIncome"
									/>
								</Form.Item>
							</Col>
							<Col className="align-self-end" xs={12} lg={6}>
								<Form.Item>
									{/* odd: field name 綜援檔案編號  */}
									<span>綜援編號</span>
									<Controller
										as={<Input />}
										control={control}
										disabled={IS_READ_ONLY}
										name="cssaFileNum"
									/>
								</Form.Item>
							</Col>
						</Row>
					</div>
					{contactPerson()}
					{MODE === "create" && (
						<Form.Item>
							<Button type="primary" htmlType="submit" disabled={isUploading}>
								儲存
							</Button>
						</Form.Item>
					)}
				</Form>
			</Tabs.TabPane>
			{/* // TODO: to be confirmed! */}
			{/* {referralInfoGetData?.referralInfoGet.approvalStatus === 'approved' && ( */}
			{MODE !== "create" && (
				<Tabs.TabPane className="assessment-tab" tab="評估" key="assessment">
					{!isDenied && (
						<Button
							className="btn btn-secondary"
							onClick={onCreateAssessmentClick}
						>
							新增
						</Button>
					)}
					<CreateAssessmentModalComponent
						userId={userGetData?.userGet?.userId}
						visible={isCreateAssessmentModalShow}
						onClose={onCreateAssessmentModalClose}
						onSubmit={onCreateAssessmentModalSubmit}
					/>
					<Table
						className="assessment-table"
						columns={assessmentCols}
						dataSource={assessments}
						rowKey="assessmentResultId"
						loading={userGetLoading}
						onChange={onAssessmentSortChange}
						pagination={false}
						scroll={{ x: "max-content" }}
					/>
				</Tabs.TabPane>
			)}
			{/* )} */}
			{/* // TODO: to be confirmed! */}
			{/* {referralInfoGetData?.referralInfoGet.approvalStatus === 'approved' && ( */}
			{MODE !== "create" && (
				<Tabs.TabPane
					className="prescription-tab"
					tab="診斷"
					key="prescription"
				>
					{!isDenied && (
						<Button
							className="btn btn-secondary"
							onClick={onCreatePrescriptionClick}
						>
							新增
						</Button>
					)}{" "}
					<CreatePrescriptionDrawerComponent
						userId={userGetData?.userGet?.userId}
						visible={isCreatePrescriptDrawerShow}
						onClose={onCreatePrescriptionDrawerClose}
						onSubmit={onCreatePrescriptionDrawerSubmit}
					/>
					<CreatePrescriptionDrawerComponent
						userId={userGetData?.userGet?.userId}
						isCreatePresciption={isCreatePresciption}
						visible={isCreatePrescriptDrawerShow}
						onClose={onCreatePrescriptionDrawerClose}
						onSubmit={onCreatePrescriptionDrawerSubmit}
						mode="add"
						prescription={prescriptions?.find(
							(data) => data.id === prescriptionId
						)}
					/>
					<CreatePrescriptionDrawerComponent
						userId={userGetData?.userGet?.userId}
						visible={viewPrescriptDrawerShow}
						onClose={onUpdatePrescriptionDrawerClose}
						onSubmit={onUpdatePrescriptionDrawerSubmit}
						mode="view"
						prescription={prescriptions?.find(
							(data) => data.id === prescriptionId
						)}
					/>
					<Table
						className="prescription-table"
						columns={prescriptionCols}
						dataSource={prescriptions}
						rowKey="id"
						loading={userGetLoading}
						onChange={onAssessmentSortChange}
						pagination={false}
						scroll={{ x: "max-content" }}
					/>
				</Tabs.TabPane>
			)}
			{/* )} */}
			{/* {console.log(referralInfoGetData?.referralInfoGet)} */}
			{/* {referralInfoGetData?.referralInfoGet.user.referralInfo.length && (
                <Tabs.TabPane className='record-tab' tab='過往審批紀錄' key='record'>
                    <Table
                        className='record-table'
                        columns={recordCols}
                        dataSource={referralInfoGetData.referralInfoGet.user.referralInfo}
                        loading={userGetLoading}
                        pagination={false}
                        rowKey="id"
                    />
                </Tabs.TabPane>
            )} */}

			{/* {userGetData?.userGet?.assessmentResult?.length && (
                <Tabs.TabPane className='assessment-tab' tab='評估' key='assessment'>
                    <Table
                        className='assessment-table'
                        columns={assessmentCols}
                        dataSource={assessments}
                        rowKey="assessmentResultId"
                        loading={userGetLoading}
                        onChange={onAssessmentSortChange}
                        pagination={false}
                    />
                </Tabs.TabPane>
            )} */}
			{/* {userGetData?.userGet?.prescription?.length && (
                <Tabs.TabPane className='prescription-tab' tab='診斷' key='prescription'>
                    <Table
                        className='prescription-table'
                        columns={prescriptionCols}
                        dataSource={prescriptions}
                        rowKey="id"
                        loading={userGetLoading}
                        onChange={onAssessmentSortChange}
                        pagination={false}
                    />
                </Tabs.TabPane>
            )} */}
			{/* {console.log(userGetData?.userGet)} */}
			{userGetData?.userGet?.referralInfo?.length && (
				<Tabs.TabPane className="record-tab" tab="過往審批紀錄" key="record">
					<Table
						className="record-table"
						columns={recordCols}
						dataSource={userGetData.userGet.referralInfo}
						loading={userGetLoading}
						pagination={false}
						rowKey="id"
						scroll={{ x: "max-content" }}
					/>
				</Tabs.TabPane>
			)}
		</Tabs>
	);
}

export default forwardRef(TabsView);

TabsView.propTypes = {
	id: PropTypes.string,
	userId: PropTypes.string,
	hkid: PropTypes.string,
	activeTab: PropTypes.string,
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string,
		hash: PropTypes.string,
		state: PropTypes.object,
	}),
	mode: PropTypes.string.isRequired,
	changeMode: PropTypes.func,
};

TabsView.defaultProps = {
	id: undefined,
	userId: undefined,
	hkid: undefined,
	changeMode: () => {},
};
